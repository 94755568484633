import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { Subject, throwError } from 'rxjs';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { DialogRef, DialogService } from '@ngneat/dialog';
import { ToastrService } from 'ngx-toastr';

import * as userActions from '@store/user';
import { selectUserInfo } from '@store/user';
import { AppState } from '@store/app.state';
import * as brandActions from '@store/brand';

import { BrandModel, UserModel } from '@app/shared/model';
import { SubscriptionDto } from '@app/shared/dto';
import { PaymentsService } from '@app/services/payments.service';
import { ValidateEqual } from '@app/shared/helpers/validate-equal';
import { HelperService } from '@app/services/helper.service';
import { logOutUser } from '@store/app.actions';
import { AuthService } from '@app/services/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-registration-dialog',
  templateUrl: './registration-dialog.component.html',
  styleUrls: [ './registration-dialog.component.scss' ]
})
export class RegistrationDialogComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<void>();
  private subscriptionData: SubscriptionDto;
  private profile: UserModel;

  public error: any;
  public loading = false;

  public brandForm = this.fb.group({
    name: [ '', [ Validators.required, Validators.min(3) ] ],
    brandLogo: [ '', [ Validators.required, Validators.min(3) ] ]
  });

  public brandUserForm = this.fb.group({
    firstName: [ '', [ Validators.required, Validators.min(3) ] ],
    lastName: [ '', [ Validators.required, Validators.min(3) ] ],
    email: [ '', [ Validators.required, Validators.email ] ],
    country: [ '', [ Validators.required, Validators.min(3) ] ],
    currency: [ 'EUR', [ Validators.required ] ],
    postCode: [ '', [ Validators.required, Validators.pattern('^[0-9]*$') ] ],
    city: [ '', [ Validators.required, Validators.min(3) ] ],
    callingCode: [ '' ],
    phone: [ '', [ Validators.required, Validators.pattern('[- +()0-9]+') ] ],
    password: [ '', [ Validators.required, Validators.min(12) ] ],
    confirmPassword: [ '', [ Validators.required, Validators.min(12) ] ],
    agree: [ null, [ Validators.required, Validators.requiredTrue ] ]
  }, {
    validators: [ this.checkIfMatchingPasswords('password', 'confirmPassword') ]
  });

  public planForm = this.fb.group({
    discountCoupon: [ '' ],
    isExtendedOptions: [ false ]
  });

  constructor(
    public ref: DialogRef,
    private router: Router,
    private fb: FormBuilder,
    private actions: Actions,
    private store: Store<AppState>,
    private translate: TranslateService,
    private toastr: ToastrService,
    private dialog: DialogService,
    private authService: AuthService,
    private helperService: HelperService,
    private paymentsService: PaymentsService
  ) {}

  ngOnInit(): void {
    this.checkIsUserExist(); // ???

    this.subscribeCreateUserSuccess();
    this.subscribeCreateUserFailure();

    this.subscribeOnCreateBrandSuccess();
    this.subscribeOnCreateBrandFailure();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string): any {
    return this.helperService.checkIfMatchingPasswords(passwordKey, passwordConfirmationKey);
  }

  private checkIsUserExist(): void {
    let userInfo;
    this.store.select(selectUserInfo).pipe(take(1)).subscribe(data => userInfo = data);
    // When user already created, but not a brand. Mock passwords values to validate form. User will proceed only with brand creation.
    // It would be nice to separate user form from brand form in the future or unite logic on backend.
    if (userInfo?.id) {
      this.profile = userInfo;
      this.brandUserForm = this.fb.group({
        firstName: [ userInfo.firstName || '', [ Validators.required, Validators.min(3) ] ],
        lastName: [ userInfo.lastName || '', [ Validators.required, Validators.min(3) ] ],
        email: [ userInfo.email || '', [ Validators.required, Validators.email ] ],
        country: [ this.brandUserForm.get('country').value || '', [ Validators.required, Validators.min(3) ] ],
        currency: [ this.brandUserForm.get('currency').value || 'EUR', [ Validators.required ] ],
        postCode: [ this.brandUserForm.get('postCode').value || '', [ Validators.required, Validators.pattern('^[0-9]*$') ] ],
        city: [ this.brandUserForm.get('city').value || '', [ Validators.required, Validators.min(3), Validators.pattern('^([a-zA-Z\u0080-\u024F]+(?:. |-| |\'))*[a-zA-Z\u0080-\u024F]*$') ] ],
        callingCode: [ this.brandUserForm.get('callingCode').value ],
        phone: [ this.brandUserForm.get('phone').value || '', [ Validators.required, Validators.pattern('[- +()0-9]+') ] ],
        password: [ '••••••••', [ Validators.required, Validators.min(12) ] ],
        confirmPassword: [ '••••••••', [ Validators.required, Validators.min(12), ValidateEqual('password') ] ],
        agree: [ true, [ Validators.required ] ]
      });
    }
  }

  private subscribeCreateUserSuccess(): void {
    this.actions.pipe(ofType(userActions.createNewUserSuccess), takeUntil(this.destroyed$))
    .subscribe(({ profile }) => {
      this.profile = profile;
      const brand = this.generateBrand(profile);
      this.store.dispatch(brandActions.createNewBrand({ brand }));
    });
  }

  private subscribeCreateUserFailure(): void {
    this.actions.pipe(ofType(userActions.createNewUserFail), takeUntil(this.destroyed$))
    .subscribe(({ error }: any) => {
      this.loading = false;
      if (error) {
        console.error(error, 'User creation failed');
        this.error = error;

        const emailError = error.user?.email;
        if (emailError) {
          this.toastr.error(emailError[0]);
        }
      }
    });
  }

  private subscribeOnCreateBrandSuccess(): void {
    this.actions.pipe(ofType(brandActions.createNewBrandSuccess), takeUntil(this.destroyed$))
    .subscribe(() => {
      this.paymentsService.createSubscription(this.subscriptionData)
      .pipe(take(1))
      .subscribe(() => {
        this.loading = false;

        const isCorporate = this.subscriptionData.is_corporate;
        if (isCorporate) {
          this.authService.logout();
          this.store.dispatch(logOutUser());
          this.ref.close();
          this.router.navigate([ '/' ]).then(() => {
            const corporatePlanMsg = this.translate.instant('paymentsDialog.notifications.corporatePlanMsg');
            this.dialog.success(corporatePlanMsg);
          });
        } else {
          this.finalRedirect();
        }
      });
    });
  }

  private subscribeOnCreateBrandFailure(): void {
    this.actions.pipe(ofType(brandActions.createNewBrandError), takeUntil(this.destroyed$))
    .subscribe(({ error }: any) => {
      this.loading = false;
      if (error) {
        console.error(error, 'Brand creation failed');
      }
    });
  }

  private generateBrand(createdUser: UserModel): Partial<BrandModel> {
    const company = this.brandForm.get('name').value;
    const currency = this.brandUserForm.get('currency').value;
    const locationCity = this.brandUserForm.get('city').value;
    const locationPostCode = this.brandUserForm.get('postCode').value;
    const origin = this.brandUserForm.get('country').value;
    const streetAddress = this.brandUserForm.get('city').value;
    const phone = this.brandUserForm.get('callingCode').value + this.brandUserForm.get('phone').value;
    const brandLogo = this.brandForm.get('brandLogo').value;
    const user = createdUser.id;
    return {
      company,
      currency,
      locationCity,
      locationPostCode,
      streetAddress,
      origin,
      phone,
      brandLogo,
      user
    };
  }

  private createNewUser(): void {
    const firstName = this.brandUserForm.get('firstName').value;
    const lastName = this.brandUserForm.get('lastName').value;
    const currency = this.brandUserForm.get('currency').value;
    const username = this.brandUserForm.get('email').value;
    const email = this.brandUserForm.get('email').value;
    const password = this.brandUserForm.get('password').value;
    const isBrand = true;

    const user = {
      firstName,
      lastName,
      currency,
      username,
      email,
      password,
      isBrand
    } as Partial<UserModel>;

    this.store.dispatch(userActions.createNewUser({ user }));
  }

  private createUserAndBrand(): void {
    if (this.profile?.id) {
      const brand = this.generateBrand(this.profile);
      this.store.dispatch(brandActions.createNewBrand({ brand }));
    } else {
      this.createNewUser();
    }
  }

  private finalRedirect(): void {
    this.store.dispatch(brandActions.getBrand());
    this.ref.close();
    this.router.navigate([ '/campaign' ]);
  }

  public submit(subscriptionData: SubscriptionDto): void {
    this.subscriptionData = subscriptionData;
    this.loading = true;
    this.createUserAndBrand();
  }
}
