<main *ngIf='pages["privacy-policy"]'>
  <section class="article black-bg">
    <div class="wrapper">
      <h1>Privacy policy</h1>
      <div class="article__wrapper">
        <div class="article__content">
          <p class="h1">Privacy Policy Social Collabz EU Regulation 679/2016d</p>
          <p>Social Collabz GmbH, tax code and registration number in the register of companies of Nordstrasse 1, 6300 Zug, Switzlerand, CH499205145, with registered office in Nordstrasse 1, 6300 Zug &ndash; (the "Company") is committed to respecting your privacy. Your personal data are processed in accordance with the principles of lawfulness, correctness and transparency, purpose limitation, data minimization, accuracy, storage limitation, integrity and confidentiality, and accountability, as well as in compliance with all other applicable provisions of EU Regulation 2016/679, adopted by the European Parliament and the Council on 27th April 2016.</p>
          <p>This Privacy Policy explains our policy in managing the personal information we collect through the following channels (hereinafter referred to as the Collection Channels):</p>
          <ul>
            <li>Our online properties (hereinafter referred to as each "Website"), including our online properties:</li>
            <li class="disable_disc">
              <ul>
                <li>Our website, is <a href="https://www.socialcollabz.com">socialcollabz.com</a></li>
                <li>Any of our linked websites, social media page, internal website and  Social Collabz mobile and tablet apps including the Social Collabz Influencer App;</li>
              </ul>
            </li>
            <li>Any other means by which a person provides personal information to Social Collabz, including both physical and electronic information; and</li>
            <li>Any other means by which we legally collect personal information about you.</li>
          </ul>
          <p>You can contact us if you have any questions regarding this Privacy Policy or your personal information by contacting the Privacy Agent by email: info@socialcollabz.com</p>
          <p>We are committed to safeguarding personal privacy. We acknowledge your right to control how we collect and use your personal information. Providing personal information is an act of trust and we take it seriously. We will collect and use personal information as defined below, except where we may request permission to operate differently, even on a case-by-case basis.</p>
          <ol>
            <li>In this Privacy Policy, we refer to your ' personal information', indicating in this expression the information or opinions about an identified individual, or an individual who is reasonably identifiable regardless of the truthfulness of the information or opinions and regardless of whether the information or opinions are recorded in a material form or not. Your full name, gender, date of birth, home address, home phone number, mobile phone number, email address and business contact details are examples of information that may constitute personal information. Personal information may also include information we may collect about your individual preferences.</li>
            <li>Social Collabz has developed and implemented its Privacy Policy regarding personal information to reflect international best practices on privacy policies.</li>
            <li>To keep us up to date with international laws and best practices, we may review this Privacy Policy at any time without notice. We will post all changes made to this Privacy Policy on our Websites, so we encourage you to check this Privacy Policy from time to time.</li>
            <li>In addition to the provisions of this Privacy Policy, there may also be specific and additional privacy and consent provisions that apply to certain Collecting Channels. Since these specific and additional provisions also relate to the protection of your privacy, we recommend that you review them wherever they appear. If there are inconsistencies between the provisions of this Privacy Policy and other specific and additional provisions, the specific and additional provisions shall prevail.</li>
          </ol>
          <p>Anonymity and pseudonyms</p>
          <ol>
            <li>Where applicable, we allow you to cooperate with us anonymously or under a pseudonym. If this is possible, our Collection Channels will only seek information in this way. However, where not applicable to the purpose for which the information is collected, we will seek the information identified below. It is not practical for us to cooperate anonymously or pseudonymously if we wish to send you direct marketing material or if we need to send you goods or services requested by you.</li>
          </ol>
          <p>Types of Personal Information Social Collabz Collects and Stores</p>
          <ol>
            <li>Personal information that may be requested includes, but is not limited to:</li>
            <li>contact information such as full name, date of birth, telephone number, mobile phone number, current and valid email address, residential address and postal code;</li>
            <li>your Apple AppStore and/or Google Play Store ID and your unique device identifier (UDID);</li>
            <li>the management and login details of your social media, including your username;</li>
            <li>location information;</li>
            <li>more detailed contact preferences;</li>
            <li>sensitive information about you;</li>
            <li>photographs of you;</li>
            <li>your occupation and information about your professional history and professional interests;</li>
            <li>information about your education and qualifications;</li>
            <li>information about your personal interests and interesting and relevant information about you;</li>
            <li>financial details including your bank account details and PayPal details;</li>
            <li>your online behavior and interests based on your online browsing activities;</li>
            <li>information about your preferences, interests, and experiences with our products or services. This information is collected to personalize our communications with you and to continually improve our products and services;</li>
            <li>information about your experience with our products or services or with third-party products or services listed on a Website;</li>
            <li>your credit card details (if you purchase any product or service from us);</li>
            <li>the contact details of third parties. If you are asked to provide details of other people, please ensure that these people are happy that their details are provided to Social Collabz and used for the purposes outlined in this policy (which may include using the details for marketing purposes); and</li>
            <li>any other personal information you provide to us directly. If you provide us with unsolicited personal information, we will retain this information if it falls within the primary scope of our collection of personal information (as outlined in this Privacy Policy).</li>
            <li>When you use a Website, we may also collect personal information about you in the following general categories:</li>
            <li>Usage Information and Preferences: We collect information about how you interact with a Website, including the pages you visit, your preferences and the settings you choose. We may do this through the use of cookies and other similar technologies that uniquely identify you;</li>
            <li>Device information: We may collect information about your mobile device, such as hardware model, operating system, preferred language, unique device identification, and mobile network; and</li>
            <li>Other Information: We may also collect and store information such as your IP address, your access data and times, the type of browser and pages visited when you interact with a Website.</li>
          </ol>
          <p>Sensitive Information</p>
          <ol>
            <li>We will only collect sensitive information with your consent and if you provide it to us directly. If you provide us with sensitive information (including but not limited to information about your sexual orientation, political and religious beliefs, medical and/or criminal history), we will use this information only for the purpose stated at the time of collection and we will share this information with our trusted third parties, as stated on the Website or in this Privacy Policy.</li>
          </ol>
          <p>How Personal Information Is Collected and Stored by Social Collabz</p>
          <ol>
            <li>We will collect or monitor personal information about you only with your consent, as outlined in this Privacy Policy or otherwise in a lawful way. The only personal information we collect is information provided to or collected by us in accordance with this Privacy Policy or that has been lawfully provided to us by third parties.</li>
          </ol>
          <p>Active Collection of Information</p>
          <ol>
            <li>Personal information may be collected (and combined) through our Collection Channels if you:</li>
            <li>you provided us with personal information before this Privacy Policy came into effect, either directly from you or through third parties;</li>
            <li>you have signed up and/or registered to become a member of any Collection Channel;</li>
            <li>you have downloaded and used a mobile application and/or tablet including the Social Collabz Influencer App;</li>
            <li>you have submitted Content or participated in any way in a Collection Channel, including engaging with other users or participating in Campaigns;</li>
            <li>you have subscribed to any newsletters, updates, alerts or news and media services, or have requested information about launches or events or information about our products or services or other information services and also information about third-party products or services;</li>
            <li>you have completed and sent us any form or request;</li>
            <li>you have contacted us directly in person or through social media including mail, telephone, social media and commercial electronic messaging (SMS (Short Message Service), MMS (Multimedia Message Service), IM (Instant Messaging) and email) including contact details listed on a Website;</li>
            <li>you have participated in any offer, promotion or marketing activity;</li>
            <li>you have interacted with a Website for a specific purpose;</li>
            <li>you have interacted with or browsed a Website in general; or</li>
            <li>If you are a company we trade with, you have completed a credit application.</li>
            <li>Personal information is required to enable you to participate in a Collection Channel, to facilitate or provide you with certain Content, products or services and information about such Content, products or services (including uploading, accessing and receiving Content on a Website and purchasing transactions performed on a Website), &nbsp;commercial materials (including direct marketing materials), newsletters, electronic newsletters (e-newsletters), new and publications in media/launch information and also to warn you of other opportunities and products, services, offers, competitions or events of Social Collabz or third parties that may be of interest to you. No one is obliged to provide personal information. However, if you do not do so you may not be able to take part in the Collection Channels and for Social Collabz it may not be possible to allow or provide you with certain Content, products or services, information about products or services, upcoming opportunities and promotions, information about competitions, offers or events or accept your submissions of competition material or other Content.</li>
            <li>We may also obtain personal information about you from authorized third-party sources including social media channels, intermediary lists, and other data providers or organizations that share data in circumstances where it is lawful to do so and/or you have given them your permission to do so. If you represent a business we do business with, we may also obtain information about you regarding loans from a credit analysis body, and information about your business history through the references you provide to us.</li>
          </ol>
          <p>Minors</p>
          <ol>
            <li>The collection of personal information is neither intended nor directed at people under eighteen (18) years of age. Personal information will not be collected from any person that Social Collabz knows is under eighteen (18) years of age without the consent of a parent or legal guardian. People under the age of eighteen (18) may use our Websites only with the involvement and consent of a parent or legal guardian. Pursuant to this document, the download of the Social Collabz App presupposes the age of majority of the User or the knowledge, by the parent, of the use by the minor.</li>
          </ol>
          <p>Passive Collection of Information</p>
          <ol>
            <li>Like many commercial websites and mobile and tablet applications, we may also collect information that lets us know more about visitors to our Websites. For example, we may collect information about the date, time and duration of visits and which pages of a Website record the highest number of accesses. This information is generally not linked to the identity of visitors, except where a Website is reached via links in an email we have sent or when we can uniquely identify your device or access to a Website. By logging into a Website via links in an email we have sent you and/or logging into a Website in general, even when you are logged in to an account, you consent to the collection of such information if it is personal information.</li>
            <li>When you browse our Websites, some information may be passively collected (i.e., collected without you actively providing the information) using various technologies, such as Unique Device Identifiers (UDIs), cookies, Internet tags or web beacons, and collections of browsing data (log files, server logs, clickstreams). This depends on the device used and the method by which the person connects to the Internet. Your Internet browser automatically transmits to the Website on which you are browsing some of this anonymous information or personal information, such as the URL of the website from which you just arrived, the Internet Protocol (IP) address, the UDI (if applicable) and the version of the browser that your device is currently using. Our Websites may also collect anonymous information or personal information from your device through cookies and internet tags or web beacons. You can set your browser to notify you when a cookie is sent or to reject all cookies, but some features of the Website may not work without cookies and this may limit the services provided by a Website. Cookies and other technical methods may include transmitting information to us directly or to another party authorized to collect information on our behalf.</li>
            <li>Our Websites may use and combine such anonymous information or personal information collected passively and/or from various third-party sources, including those described above, and may combine this anonymous information or personal information with other personal information collected from you to better serve visitors and users of the Website, &nbsp;to tailor a Website to your preferences, to compile and analyze statistics and trends, to provide you with relevant advertisements when you visit a Website or a third-party website, and to administer and otherwise improve a Website for your use. We may combine information about your visiting sessions or other information collected through tracking technologies with personally identifiable information from time to time to understand and measure your online experiences and to determine which products, promotions and services you are likely to be interested in. By accessing a Website, you consent to the collection, processing and use of information about you in this way.</li>
            <li>For more information about cookies and what choices you can make, visit <a href="https://www.google.com/url?q=http://www.youronlinechoices.com.au/&amp;sa=D&amp;source=editors&amp;ust=1674076500473592&amp;usg=AOvVaw204cP5BxSUGJDGzaZAmzNp">http://www.youronlinechoices.com.au/</a>.</li>
          </ol>
          <p>Purposes for which Social Collabz collects, stores, uses and discloses personal information</p>
          <ol>
            <li>The personal information collected will be used for the following primary purposes:</li>
            <li>For the purposes listed in the collection privacy statement on a particular Collection Channel.</li>
            <li>To maintain the functionality of a Website, including providing information relating to Content available on the Website and e-commerce transactions performed through the Website.</li>
            <li>To fulfill obligations under all Terms of Use on uploads, sales, and purchase contracts, and/or any other contract between you and Social Collabz.</li>
            <li>To send you technical, administrative, or legal notices that are important to our Websites.</li>
            <li>To allow you to create an account, log in and use the functionality of a particular Collection Channel including the Social Collabz Influencer App.</li>
            <li>To allow you to respond to opportunities, briefs, or Campaigns created by other users of a Collection Channel;</li>
            <li>To allow you to post on your social media channels through a Collection Channel.</li>
            <li>To facilitate payments from and to you through a Collection Channel.</li>
            <li>To provide you with information about your transactions (including uploading, accessing and receiving Content on a Website, and purchase transactions executed on a Website), Content, services, and products.</li>
            <li>To provide marketing materials, events, special offers, competitions, and promotions in person and through any means of communication, including mail, telephone, and commercial electronic messaging (SMS), MMS (Multimedia Messaging Service), IM (Instant Messaging), email) or any other form of the electronic, emerging, digital or conventional communication channel.</li>
            <li>To provide you with relevant advertisements when you use our Websites or those of third parties.</li>
            <li>To maintain our relationship with you.</li>
            <li>To provide services related to our company (for example after-sales services and requests).</li>
            <li>To provide you with newsletters by post and electronic newsletters (e-newsletters) via commercial electronic messaging.</li>
            <li>To respond to customer requests and complaints and to handle complaints.</li>
            <li>To improve the Website and system administration.</li>
            <li>To obtain opinions or comments on products and/or services and to conduct other research and development activities.</li>
            <li>To record statistical data for marketing analysis and to conduct market research.</li>
            <li>To share personal information with our group companies, our promotional partners, trusted brands that use a particular Collection Channel and their related corporate bodies and agents, and other trusted third parties in the manner described below.</li>
            <li>If you are a business with which we are in business, to continue our business relationship with you, including identifying you and contacting you, sending you invoices correctly, assessing the risks of providing credit to you or your business, and securing and recovering any debt owed to us.</li>
            <li>Any other purpose that may be deemed reasonably necessary by Social Collabz depending on the circumstance.</li>
            <li>For the purposes described above, personal information may be shared with Social Collabz group companies, Social Collabz's business partners and their related corporate bodies and other trusted third parties (and their directors, officers, and agents) in Italy or abroad. Failure to provide personal information may result in Social Collabz not being able to provide you with certain Content, information, upcoming opportunities, promotions, events, or product information and/or acceptance of Content and submissions from you relating to promotions (where applicable).</li>
            <li>To operate a Website or provide a service (including, for example, processing credit card payments), personal information may also be shared with Social Collabz companies, selected service providers and their related corporate bodies, and/or other trusted third parties in Italy or abroad. Social Collabz group companies, selected service providers, and/or other trusted third parties may be engaged by Social Collabz to perform various functions, such as legal and accounting services, data retention, order fulfillment, conducting market research, processing credit card payments, assisting with promotions and providing technical services for our Websites. These companies may have access to personal information if this is necessary for the performance of those functions. Your credit card details are only used to simplify your purchase. They are not used for any other purpose and will never be provided to third parties other than Social Collabz or our related service provider. If you are a business we are doing business with, we may share your information with debt recovery agents or credit reporting bodies as necessary.</li>
            <li>We acknowledge the trust with which you provide your personal information, and except as set forth in this Privacy Policy or as otherwise legal, such information will not be used or disclosed for any other purpose without your consent. However, Social Collabz reserves the right to use or disclose any information, including personal information, as necessary to comply with laws, regulations, or legal requests, to protect the rights or property of Social Collabz, any member of the Social Collabz, or any member of the public, to protect the integrity of a Website, to comply with your requests or to cooperate with any investigative investigation or investigation relating to public safety.</li>
          </ol>
          <p>Contact from Social Collabz</p>
          <ol>
            <li>The companies of the Social Collabz (and their directors, officers, and agents), Social Collabz's promotional partners and their related corporate bodies or trusted third parties (including users of a Collection Channel), and other selected service providers and other non-Social Collabz companies or professionals in Italy or abroad, may contact you by phone, SMS (Short Message Service), &nbsp;MMS (Multimedia Messaging Service), IM (Instant Messaging), email, post or any other form of an electronic, emerging, digital or conventional communication channel using the information provided to contact you for the main purposes of collecting personal information as stated above.</li>
            <li>Social Collabz does not send advertising or marketing information without obtaining prior consent, such as consent contained in this Privacy Policy, or as otherwise legal. If you receive communications from Social Collabz that you do not wish to receive, you may remove your name from the database as indicated in the communication, including by using the Unsubscribe/Unsubscribe feature (if the communication is via commercial electronic messaging) or by contacting Social Collabz via the contact details in this Privacy Policy. Please allow 30 days to process the request.</li>
            <li>Despite the removal of your name from the database for the receipt of future advertising and marketing information, Social Collabz may send you non-commercial "Administrative Emails". Administrative Emails refer to the Social Collabz user account and may include administration and transaction confirmations, requests, and questions or information about a particular Social Collabz user account. If you do not wish to receive such communications from Social Collabz, you may remove your name from the database using the unsubscribe function.</li>
          </ol>
          <p>Possibility for other people to view information</p>
          <ol>
            <li>Social Collabz may provide areas on a Website where you can upload User Generated Content, post or provide information about yourself, communicate with other users including business users and brands, provide reviews about Content, products, and/or services, use features that allow you to post Content on your social media channels or interact with or rate particular Content. This information may be shared with others and may be posted publicly on our Websites, including but not limited to other social media platforms and other public forums in which you may choose to participate. This information may be made available to the public and may be read, collected, and used by others on our Websites or outside our Websites. Social Collabz is not responsible for the conduct of other persons who may read, collect and use this information.</li>
          </ol>
          <p>Your right to access</p>
          <ol>
            <li>You have the right to access and review at any time your personal information that can be recorded on the Social Collabz database. The information may be reviewed by contacting Social Collabz's privacy agent with the contact details contained in this Privacy Policy. &nbsp;30 days are needed to process the request.</li>
            <li>From time to time Social Collabz may determine that it is not necessary to provide individual access to personal information to the extent that Social Collabz can lawfully do so. If such a determination is made, Social Collabz will notify you in writing.</li>
            <li>If you search for information contained in an electronic file or if you are looking for hard copies of personal information, we reserve the right to charge reasonable handling/mailing fees as necessary.</li>
            <li>For some personal information that may have been retained, for example, if it is stored on tape programs, it is impractical to provide you with this information without charging an appropriate fee that we consider reasonable in these circumstances.</li>
          </ol>
          <p>Edit and delete your account and/or information about you</p>
          <ol>
            <li>The information contained in the database or your contact preferences may be changed, by contacting Social Collabz's privacy agent via the contact details contained in this Privacy Policy. 30 days are needed to process the request.</li>
            <li>Likewise, a request to delete your account and/or personal information may be made at any time and without any restriction. In this case, your account will be deleted, and/or all reasonable steps will be taken to delete the information, except where it is legally necessary. Deleting information may result in our inability to facilitate or provide you with information about certain transactions (including uploading, accessing, and receiving Content on a Website and purchase transactions made on a Website), other Content, information about services or products, information about upcoming promotions, competitions or events, and/or providing you with certain Content, &nbsp;goods or services.</li>
            <li>We are not responsible for removing your personal information from the lists of third parties who have previously provided information about you in accordance with this policy.</li>
          </ol>
          <p>Storage and security of personal information</p>
          <ol>
            <li>Social Collabz will make every effort to keep secure all your registered personal information (including your credit card details), and to keep this information accurate, current, complete, and relevant. We ensure that only the necessary entities will have access to your personal information. Personal information is stored on secure servers protected with controlled equipment. This service can be managed on our behalf and the data can be stored by our selected service providers. In some cases, this equipment may be located overseas, including but not limited to the United States of America, the Philippines, Europe, and the United Kingdom.</li>
          </ol>
          <p>Disclosure of personal information to foreign recipients</p>
          <ol>
            <li>In some cases Social Collabz may disclose your personal information to overseas recipients, including but not limited to France, the United States of America, the Philippines, Europe, and the United Kingdom. Social Collabz's employees, processors, and other trusted third parties are obliged to respect the confidentiality of any personal information stored by Social Collabz. However, the security of communications on the Internet cannot be guaranteed, so absolute assurance that information will always be safe cannot be given. Social Collabz cannot be held responsible for events resulting from unauthorized access to personal information.</li>
          </ol>
          <p>Social Collabz and links to other websites</p>
          <ol>
            <li>Our Websites may from time to time contain links to websites of other organizations that may be of interest to you. Their inclusion shall not be deemed to imply any validation or certification by us of the Third Party Website Content. The linked websites are responsible for their own personal privacy practices and you should check the respective privacy statements of those websites. Social Collabz is not responsible and does not accept any responsibility for the conduct of companies linked to our Websites.</li>
            <li>We may use third party advertisements on our Websites. All third-party advertisements, if paid, are paid for by competent third-party advertisers and are not recommendations or sponsorships by Social Collabz or any of its affiliates. Social Collabz is not responsible for the Content (including statements) of any third-party advertisements on a Website. These advertisements may be associated with cookies that allow the advertiser to track the number of anonymous users who respond to the Campaign. We do not have access to or control cookies placed by third parties.</li>
          </ol>
          <p>Websites related to Social Collabz</p>
          <ol>
            <li>All Websites operated by Social Collabz adhere to this Privacy Policy. However, the policies of some members of the Social Collabz group on the Websites may vary due to local customs, practices, or laws.</li>
          </ol>
          <p>Sale of the Company</p>
          <ol>
            <li>If Social Collabz merges or is purchased by another company, or sells a portion or all of its assets, your personal information may be disclosed to our advisors and advisors of possible buyers, and may be part of the transferred assets. However, personal information will always be subject to this Privacy Policy.</li>
          </ol>
          <p>Problems, cancellations or requests</p>
          <ol>
            <li>If you have any questions about our Privacy Policy, or any issues or complaints about how we have collected, used, stored, managed, and/or disclosed your personal information, please contact our privacy agent via one of the following channels:</li>
          </ol>
          <p>Address:</p>
          <p>The Privacy Officer</p>
          <p>Social Collabz</p>
          <p>Nordstrasse 1</p>
          <p>6300 Zug, Switzlerand</p>
          <p>Email: <a href="mailto:info@socialcollabz.com">info@socialcollabz.com</a></p>
          <p>To delete your account, please proceed via the appropriate button available in the Social Collabz Influencer App.</p>
        </div>
      </div>
    </div>
  </section>
</main>


<main *ngIf='pages["cookie-policy"]'>
  <section class="article black-bg">
    <div class="wrapper">
      <h1>Cookie Policy</h1>
      <div class="article__wrapper">
        <div class="article__content">
          <p class="h1">Cookie Policy of <a href="https://www.socialcollabz.com" target="_blank">www.socialcollabz.com</a></p>

          <p>
            This document contains information about the technologies that allow this Application to achieve the purposes described below. These technologies allow the Owner to collect and save information (for example through the use of Cookies) or to use resources (for example by running a script) on the User’s device when the latter interacts with this Application.<br/><br/>
            For simplicity, in this document these technologies are briefly defined as “Tracking Tools”, unless there is reason to differentiate. For example, although Cookies can be used in both web and mobile browsers, it would be inappropriate to speak of Cookies in the context of applications for mobile devices, since these are Trackers that require the presence of a browser. For this reason, within this document the term Cookie is used only to specifically indicate that particular type of Tracker.
            Some of the purposes for which Tracking Tools are used may also require the User’s consent. If consent is given, it can be freely revoked at any time by following the instructions contained in this document.<br/><br/>
            This Application uses Trackers managed directly by the Owner (commonly called “first-party” Trackers) and Trackers that enable services provided by third parties (commonly called “third-party” Trackers). Unless otherwise specified herein, such third parties have access to their respective Trackers.<br/><br/>
            Duration and expiry of Cookies and other similar Tracking Tools may vary depending on what is set by the Owner or by each third-party provider. Some of them expire at the end of the User’s browsing session.<br/><br/>
            In addition to what is specified in the description of each of the categories below, Users can obtain more detailed and updated information on the duration, as well as any other relevant information - such as the presence of other Trackers - in the privacy policies of the respective third-party providers (via the links made available) or by contacting the Data Controller.<br/><br/>
          </p>
        </div>
      </div>
    </div>
  </section>
</main>

<section class="delete-account mt-160 mb-100" *ngIf='pages["delete-account"]'>
    <div class="delete-account__title">
      How to request to delete your account and data
    </div>

    <div class="delete-account__cont">
      <div class="delete-account__text">
        To delete your account, please go to the "Profile" tab, then tap "Your profile", you will see the "Delete my account" button.
        Tap on it to request to delete your account and your data: <br>
      </div>
      <div style="display: flex; align-content: center; justify-content: center">
        <img style="display: flex; width: 300px" alt="Delete my account" src="assets/images/delete-account.jpeg"/>
      </div>
      <div class="delete-account__text">For any questions, please get in touch with us at <a href="mailto:info@socialcollabz.com">info@socialcollabz.com</a></div>
    </div>
</section>

<main *ngIf='pages["terms-influencer"]'>
  <section class="article black-bg">
    <div class="wrapper">
      <h1>TERMS & CONDITIONS</h1>
      <div class="article__wrapper">
        <div class="article__content">
          <p class="h1">GENERAL TERMS AND CONDITIONS OF INFLUENCER CONTRACT</p>
          <p><b>LA PIATTAFORMA SOCIAL COLLABZ</b></p>
          <p><p>
          <strong>GENERAL TERMS AND CONDITIONS OF INFLUENCER CONTRACT</strong>
          <strong></strong>
        </p>
          <p>
            <strong>1. </strong>
            <strong>THE SOCIAL COLLABZ PLATFORM</strong>
            <strong></strong>
          </p>
          <p>
            1.1 The Social Collabz GmbH, tax
            code and registration number in the register of companies of
            Nordstrasse 1, 6300 Zug, CH499205145, with registered
            office in Nordstrasse 1, 6300 Zug, Switzlerand ("<strong>Social Collabz</strong>").
          </p>
          <p>
            1.2 Social Collabz has created the platform to allow the <strong>Brand</strong> and the Influencer to interact, establish
            relationships and create and distribute sponsored <strong>posts</strong>
            through the Social Influencer <strong>Channels</strong> in the<strong>Influencer Community</strong>    <a name="_Hlk5218058">.<strong></strong></a>
          </p>
          <p>
            1.3 These General Terms and Conditions of Influencer Contract (the "    <strong>GTCI")</strong> explain how to access and use the Social Collabz
            platform, which are fully accepted by joining and/or using the Platform by
            the Influencer.
          </p>
          <p>
            1.4 The contractual position of the Brand is governed by the General Terms
            and Conditions of Brand ("<strong>GTCB"),</strong> which are fully accepted
            by joining and/or using the Platform by the Brand.
          </p>
          <p>
            1.5 Upon the approval of the GTCI and the GTCB, Social Collabz grants the
            Influencer and the Brand a limited, non-exclusive, revocable and
            non-transferable license to access and use the Social Collabz platform.
          </p>
          <p>
            1.6 The acceptance of the GTCI and the GTCB does not determine between the
            Influencer, the Brand, and Social Collabz any relationship other than that of
            use of the Social Collabz platform as described and, in particular, for
            purely illustrative but not exhaustive purposes, it does not determine any    <em>partnership</em> or agency, employment, fiduciary relationship.
          </p>
          <p>
            1.7 Access and/or use of the Social Collabz platform is subject to
            acceptance of the GTCI by the Influencer and the GTCB by the Brand.
          </p>
          <p>
            1.8 Each time the Influencer accesses and uses the Social Collabz platform,
            the Influencer agrees to comply with the GTCI. If the Influencer does not
            wish to comply with the GTCI, the Influencer must not access and/or use the
            Social Collabz platform.
          </p>
          <p>
            1.9 Each time the Brand accesses and uses the Social Collabz platform, it
            agrees to comply with the GTCB. If the Brand does not intend to comply with
            the GTCB, it must not access and/or use the Social Collabz platform.
          </p>
          <p>
            1.10 The Social Collabz platform allows the Brand to allocate a<strong>Campaign Budget</strong> to create an <strong>Advertising</strong>    <strong>Campaign</strong> using the <strong>Campaign Builder</strong>
            functionality to share it with the Influencer.
          </p>
          <p>
            1.11 The Influencer will access the Advertising Campaign through the    <strong>Social Collabz App</strong>, creating and sending a Post to the Brand
            for approval, it is understood that the Brand may refuse it or request
            modifications.
          </p>
          <p>
            1.12 The Influencer may proceed with the agreed Post's publication in case
            of the Brand's approval, thus executing the Advertising Campaign.
          </p>
          <p>
            1.13 The Influencer will receive the <strong>Post</strong>    <strong>Fee</strong> only in case of approval of the Post and after the
            publication of the post through the Social Influencer Channels.
          </p>
          <p>
            1.14 The Social Collabz platform is designed for use by people aged
            eighteen (18) years and older. Influencers and Brands are solely
            responsible for each interaction on the Social Collabz platform.
            <a name="_Hlk5442408">
              If Social Collabz becomes aware of the access of a person under eighteen
              (18) years of age
            </a>
            to the Social Collabz platform, it will proceed to exclude that person from
            the platform.
          </p>
          <p>
            1.15 Social Collabz reserves the right to modify the GTCI at any time by
            updating the relevant website and notifying the Influencer by sending a
            communication to the <em>e-mail</em> address indicated in the    <strong>Influencer</strong><strong> </strong><strong>Account</strong>.
          </p>
          <p>
            1.16 Social Collabz reserves the right to intervene on the Social Collabz
            platform by making necessary and/or appropriate changes and for maintenance
            purposes, notifying the Influencer using the <em>e-mail</em> address
            indicated in the Influencer Account. During the interventions, access to
            and use of the Social Collabz platform may be temporarily suspended, it is
            understood that Social Collabz must provide specific notice at least twenty-four
            (24) hours before, by using the <em>email</em> address indicated in the
            Influencer Account and/or <strong>Brand Account.</strong>
          </p>
          <p>
            1.17 Social Collabz disclaims any responsibility for the process of creating the
            Advertising Campaign, publishing the Post, and paying the Post Fee, in
            consideration of the fact that all these phases are managed exclusively by
            the Influencer and the Brand in complete autonomy.
          </p>
          <p>
            1.18 Social Collabz disclaims any responsibility regarding the material uploaded
            on the Social Collabz platform by the Influencer and/or by the Brand, as it
            does not assume any commitment to moderate, approve, monitor, modify,
            remove the content, nor to verify its legality, validity, accuracy,
            adequacy.
          </p>
          <p>
            1.19 The Influencer and the Brand will not be able to negotiate the
            execution and/or payment of an Advertising Campaign outside the Social
            Collabz platform.
          </p>
          <p>
            1.20 The Influencer and the Brand are committed: i) not to decompile,    <em>reverse engineer</em>, disassemble in any way any element of the Social
            Collabz platform, including through third parties; ii) not to distribute or
            republish any element of the Social Collabz platform in any way; iii) not
            to resell, rent, or allow the use, even temporary, of any element of the
            Social Collabz platform in any way; iv) not to tamper with, disable or
            circumvent any security feature of the Social Collabz platform in any way;
            v) not to transfer any element of the Social Collabz platform to any third
            party in any way; vi) to guarantee access by Social Collabz to information
            relating to location and geolocation; vii) to guarantee Social Collabz the
            possibility of using the respective logo about the services and/or products
            provided.
          </p>
          <p>
            1.21 The Influencer and the Brand agree not to participate in any    <em>crawling</em>, <em>scraping</em>,<em> or caching</em> activity, not to
            access in any way any content of the Social Collabz platform through any
            means, except by the express authorization of Social Collabz, which will be
            communicated in writing via the Social Collabz platform upon request
            submitted through the platform.
          </p>
          <p>
            1.22 The Social Collabz platform may contain <em>links</em> to other sites
            not operated by Social Collabz ("<strong>Linked Sites</strong>"). Social Collabz
            disclaims all responsibility for the content of the Linked Sites, their
            quality, and the nature of the products or services offered, used, or
            advertised by them. Social Collabz also disclaims all responsibility for any
            collection and/or use of data and/or information that may be performed by
            the Linked Sites. The Brand and the Influencer assume all responsibility
            for access to and/or use of the Linked Sites.
          </p>
          <p>
            1.23 The Social Collabz platform may also use social <em>networks</em> or
            share some features and may contain <em>links</em>, applications, or other    <em>social media</em> features (including but not limited to Facebook,
            Twitter, and Instagram; the "<strong>Connected Socials</strong>"). The
            Influencer and the Brand accept the conditions of use provided by the
            Connected Social Networks used by the Social Collabz platform. The
            Influencer and the Brand also allow the Social Collabz platform to access
            all the information shared on the Connected Social Networks. Social Collabz
            suggests that you check the terms and conditions of use of the Social
            Networks Connected to the Social Collabz platform before selecting the
            relevant <em>links</em>, applications, or functions and/or interacting with
            them. The Social Collabz platform is in no way sponsored, subscribed,
            administered, or associated with any of the Linked Social Networks.
          </p>
          <p>
            1.24 All costs arising from the <em>download</em>, installation, access,
            and use of the Social Collabz platform are borne by the Influencer, which
            is solely responsible for the use of the Social Collabz platform.
          </p>
          <p>
            1.25 The Influencer and the Brand must verify and ensure that the devices
            used are compatible with the Social Collabz platform and that they comply
            with all the technical specifications necessary for access and use of the
            platform. Social Collabz disclaims any responsibility for the accessibility to
            the Social Collabz platform about the connection service chosen by the
            Influencer and/or the devices used.
          </p>
          <p>
            <a name="_Hlk5200540">1.26 In the event of a violation of</a>
            the individual prohibitions and/or non-compliance with the individual
            obligations referred to in the previous points, Social Collabz reserves the
            right to suspend and/or exclude the Brand from the Social Collabz platform.
          </p>
          <p>
            <a name="_Hlk5220832">
              1.27 The provisions of these GTCB relating to Brands also apply to        <strong>Media Agencies</strong>.
            </a>
          </p>
          <p>
            <strong>2. </strong>
            <strong>INFLUENCER ACCOUNT REGISTRATION</strong>
            <strong></strong>
          </p>
          <p>
            2.1 To use the Social Collabz platform, the Influencer must download the
            Campaign Builder and register its Influencer Account, as provided for in
            the GTCI.
          </p>
          <p>
            2.2 Social Collabz reserves the right to refuse registration and/or cancel the
            Influencer Account at its sole discretion.
          </p>
          <p>
            2.3 All information provided when registering for the Influencer Account
            must be current, correct, and complete. Incomplete, unsuitable, and/or
            incomprehensible registrations will not be considered valid. You must    <em>log in</em> in the manner required each time you wish to use the Social
            Collabz platform and it is forbidden to use another person's Influencer
            Account without their prior consent.
          </p>
          <p>
            2.4 The Influencer is required to maintain the strictest confidentiality
            regarding the details of its Influencer Account and any activity carried
            out on the Social Collabz platform.
          </p>
          <p>
            2.5 The Influencer agrees to notify Social Collabz immediately of any
            unauthorized use of its Influencer Account or any other breach of security.
            Access to and use of the Influencer Account is the sole responsibility of
            the Influencer, which may notify the cancellation at any time.
          </p>
          <p>
            2.6 Social Collabz shall not be liable for any liability and/or damage suffered
            by the Influencer in connection with non-compliance with the GTCI.
          </p>
          <p>
            2.7 Social Collabz reserves the right to delete the Influencer Account and/or to
            prohibit the Influencer from accessing the Social Collabz platform (or any
            element thereof) if it considers that the Influencer: i) is making use of
            the platform not in accordance with the GTCI; ii) intends to tamper in any
            way with the platform (or any of its elements); iii) has violated the GTCI
            and/or has been involved in any illegal and/or illegal activity or, in any
            case, incorrect as it is aimed at endangering the normal management of the
            Social Collabz platform (or any of its elements).
          </p>
          <p>
            2.8 It is strictly forbidden the use any automated <em>software</em> or
            other mechanical and/or electronic means that allow the creation of one or
            more Influencer Accounts. Social Collabz reserves the right to suspend or delete
            any Influencer Account created in violation of this provision.
          </p>
          <p>
            2.9 The information that will be provided by the Influencer for the
            creation of the Influencer Account will be addressed only to Social Collabz and
            the Influencer will be responsible for their correctness.
          </p>
          <p>
            2.10 The Influencer expressly indemnifies Social Collabz, the Linked Social
            Networks, and/or the Linked Sites in relation to the consequences of any
            loss, action, claim, cost, expense, and damage (of any nature) that may
            arise in the use of the Social Collabz platform.
          </p>
          <p>
            2.11 Any questions, comments, and/or complaints concerning the Social
            Collabz platform must be addressed exclusively to Social Collabz and may not be
            transmitted to other parties and/or Linked Social Networks.
          </p>
          <p>
            2.12 In the event of a violation of individual prohibitions and/or
            non-compliance with the individual obligations referred to in the previous
            points, Social Collabz reserves the right to suspend and/or exclude the
            Influencer from the Social Collabz platform.
          </p>
          <p>
            <strong>3. INFLUENCER REQUIREMENTS</strong>
            <strong></strong>
          </p>
          <p>
            3.1 Any person at least aged eighteen (18) years and older, capable of
            understanding and willing, can create and register an Influencer Account on
            the Social Collabz platform. If Social Collabz becomes aware of the access of a
            person under eighteen (18) years of age to the Social Collabz platform, it
            will proceed to exclude that Account from the Platform.
          </p>
          <p>
            3.2 In order to participate in the creation of an Advertising Campaign and
            accrue the right to pay a Post Fee and/or a fee through the Social Collabz
            platform, the Influencer must demonstrate that he or she meets the
            following minimum <em> standard </em> requirements:
          </p>
          <p>
            a. at least fifteen hundred (1500)<em> followers</em> on a single Social
            Influencer Channel;
          </p>
          <p>
            b. Social Influencer Channels must be public (visible to anyone);
          </p>
          <p>
            c. the Social Influencer Channels may not contain material that conflicts
            with the GTCI or the terms of use of the social media platform and/or    <em>social networks</em> of the Influencer's Social Channels.
          </p>
          <p>
            3.3 If even one of these minimum <em>standard</em> requirements is not met,
            even partially, the Influencer will not be able to participate in the
            creation of any Advertising Campaign and will not be able to access all the
            features of the Social Collabz platform.
          </p>
          <p>
            3.4 In the event of a violation of individual prohibitions and/or
            non-compliance with the individual obligations referred to in the previous
            points, Social Collabz reserves the right to suspend and/or exclude the
            Influencer from the Social Collabz platform.
          </p>
          <p>
            <strong>4. </strong>
            <strong>DOWNLOAD THE SOCIAL COLLABZ APP </strong>
            <strong></strong>
          </p>
          <p>
            4.1 To register an Influencer Account, the Influencer must download the
            Social Collabz App and log in to it with their Facebook, Instagram, or Twitter    <em>account</em>.
          </p>
          <p>
            4.2 To send a Post via the Social Collabz App, the Influencer must connect to
            their Facebook, Instagram, or Twitter<em> account</em> and/or in the manner
            requested by the Social Collabz platform.
          </p>
          <p>
            4.3 As a result of the connection to their Facebook, Instagram, or Twitter    <em>accounts</em>, the Influencer adheres to the procedure of publication
            of Posts, by Social Collabz, directly on the Social Influencer Channels, in
            compliance with the GTCI, allowing Social Collabz access to the information
            contained on the Social Influencer Channels.
          </p>
          <p>
            4.4 In the event of a violation of individual prohibitions and/or
            non-compliance with the individual obligations referred to in the previous
            points, Social Collabz reserves the right to suspend and/or exclude the
            Influencer from the Social Collabz platform.
          </p>
          <p>
            <strong>5. PAYMENTS TERMS FOR INFLUENCER</strong>
            <strong></strong>
          </p>
          <p>
            5.1 Within twenty-four (24) hours of the approval of the Post by the Brand,
            the Influencer receives, through the Social Collabz platform, a request for
            publication of the approved Post on the Social Influencer Channels.
          </p>
          <p>
            5.2 After the approval by the Brand it will no longer be possible to modify
            the Post.
          </p>
          <p>
            5.3 The Influencer will be solely responsible for the publication on the
            Social Influencer Channels of the Post after the approval by the Brand.
          </p>
          <p>
            5.4 After the publication of the post and having received the sum agreed
            with the Brand through the Social Collabz platform, the influencer
            transfers to FyTrendy and the Brand the intellectual property rights of the
            approved content for each use provided for by the legal system.
          </p>
          <p>
            5.5 Social Collabz declines all responsibility for the use of the Post by the
            Brand.
          </p>
          <p>
            5.6 In order to receive any payment through the Social Collabz platform,
            the Influencer must provide his/her bank credit details and in particular
            his/her bank account details or his/her PayPal details, as well as the
            details of his/her tax regime (for example, GST, ABN, VAT or IVA number)
            and any other useful data.
          </p>
          <p>
            5.7 After the publication of the Post, the Influencer will be entitled to
            receive payment of the Post Fee (plus GST, VAT, or IVA) provided that the
            GTCI have been complied with. In case of non-compliance with the GTCI, the
            payment will not be processed. The Post Fee (plus GST, VAT, or IVA) will be
            recognized net of the fees for crediting the indicated bank account or
            PayPal account by electronic transfer of funds within two (2) days of
            receipt of payment by the Brand. Social Collabz will issue a payment receipt to
            the Brand once the payment has been processed.
          </p>
          <p>
            5.8 The Influencer acknowledges that the Brand will be solely responsible
            for the payment of the Post Fee and any other amounts due in connection
            with the use of the Social Collabz platform. The method and timing of the
            payment of the fee by the Brand in favor of the Influencer may be the
            subject of express negotiation, through Social Collabz and the Social Collabz
            platform. The Influencer exempts Social Collabz from any liability for
            non-payment of the Post Fee and any other amount due in connection with the
            use of the Social Collabz platform.
          </p>
          <p>
            5.9 In the event of a violation of individual prohibitions and/or
            non-compliance with the individual obligations referred to in the previous
            points, Social Collabz reserves the right to suspend and/or exclude the
            Influencer from the Social Collabz platform.
          </p>
          <p>
            <strong>6. TERMS OF INFLUENCER PARTICIPATION IN THE ADVERTISING</strong>
            CAMPAIGN<strong></strong>
          </p>
          <p>
            6.1 Posts must comply with the requirements contained in the GTCi and any
            additional requirements indicated by the Brand for the requested
            Advertising Campaign. Posts that do not comply with these conditions may be
            removed from the Social Collabz platform at the sole discretion of
            Social Collabz.
          </p>
          <p>
            6.2 The Influencer must highlight the connection with the Brand in their
            Posts so that it is clear to anyone viewing the Social Influencer Channels
            that there is a relationship between the Influencer and the Brand. This
            relationship can be achieved through the use of <em>hashtags</em> such as
            #adv or #sponsored or through other means adapted to the particular
            situation, such as the specific reference to an Influencer Community and/or
            certain Social Influencer Channels. Social Collabz reserves the right to review
            and monitor the Posts and their disclosure, requiring at its sole
            discretion higher and/or different levels of implementation.
          </p>
          <p>
            6.3 Influencers must not distort the size of their audience or their number
            of <em>followers</em> or the reach of <em>engagement</em>. The Influencer
            must obtain and consolidate their followers in an organic way and not
            through forced procedures such as, for example (by way of example but not
            limited to) the purchase of <em>followers</em>, <em>likes,</em> or    <em>engagement</em>.
          </p>
          <p>
            6.4 The Influencer, for each Post uploaded on the Social Collabz App and sent to
            a Brand for approval and/or published on an Influencer Channel through the
            Social Collabz platform, guarantees that:
          </p>
          <p>
            a. is at least eighteen (18) years of age and is capable of understanding;
          </p>
          <p>
            b. owns the <strong>Intellectual Property Rights</strong> in the Post and
            has the right to license the Post to Social Collabz and the Brand in accordance
            with the GTCI;
          </p>
          <p>
            c. the Post does not violate any rights inherent in the protection of    <em>privacy</em>, contractual rights, and/or other rights (including
            Intellectual Property Rights) of any person;
          </p>
          <p>
            d. the Post does not contain any misrepresentation;
          </p>
          <p>
            e. all opinions and points of view presented in the Post in question are
            genuinely sourced by the Influencer;
          </p>
          <p>
            f. all statements contained in the Post concerning the use or experience of
            the Influencer with the products and/or services of the Brand are true,
            correct and representative of his/her genuine opinion, regardless of the
            agreement of a consideration. The Influencer, therefore, will promptly
            notify Social Collabz of any change of opinion about the Brand;
          </p>
          <p>
            g. the Post does not contain any representation or material that the
            Influencer knows or suspects (or should reasonably know or suspect) to be
            false or, deceptive and/or illusory;
          </p>
          <p>
            h. the Post is original and does not contain materials that have been
            transmitted, published, or otherwise communicated previously by the
            Influencer to the public in any way and does not contain materials
            previously used for <em>marketing</em> campaigns and/or promotions for
            third parties, or that otherwise infringe the rights of third parties;
          </p>
          <p>
            i. if it is a member of organizations and/or associations, which has not
            entered into other commitments, is not bound by other provisions and /or
            arrangements, and which may jeopardize its membership and compliance with
            the GTCI, the implementation of the commitments <em>therein </em> provided
            for and/or respect for the rights described <em>therein</em>, and/or
            determine any conflict of interest;
          </p>
          <p>
            j. if the Post contains images and/or references to third parties and/or
            rights of third parties, that they have been informed and have accepted in
            writing that such images and/or references may be used by the Brand in
            compliance with the GTCI, without that this gives rise to no right to
            receive compensation from such third parties;
          </p>
          <p>
            k. the mere use of the Post and the exercise of the Intellectual Property
            Rights in the Post in question by the Brand and Social Collabz will not violate
            any legal rights, <em>copyrights,</em> or other Intellectual Property
            Rights of any person, nor will it give rise to any kind of liability in the
            execution of payments.
          </p>
          <p>
            6.5 The Influencer acknowledges and accepts that the Brand and Social Collabz
            have the right to request moderation at any time the Post after publication
            on an Influencer Channel.
          </p>
          <p>
            6.6 The influencer will therefore immediately make any reasonable changes
            and/or revisions requested by Social Collabz and/or the Brand, in compliance
            with the GTCI. The Influencer also acknowledges and accepts that, if a Post
            with incorrect content is published, the Brand or Social Collabz may request to
            publish the correct Post again after the approval procedure.
          </p>
          <p>
            6.7 The Influencer acknowledges and accepts that the Brand and Social Collabz
            have the right to request and at any time to remove any Post published on
            the Social Influencer Channels and undertakes to comply with this request
            in compliance with the GTCI.
          </p>
          <p>
            6.8 In the event of a violation of individual prohibitions and/or
            non-compliance with the individual obligations referred to in the previous
            points, Social Collabz reserves the right to suspend and/or exclude the
            Influencer from the Social Collabz platform.
          </p>
          <p>
            <strong>7. </strong>
            <strong>INFLUENCER RIGHTS</strong>
            <strong></strong>
          </p>
          <p>
            7.1 The Influencer owns all rights to the Post.
          </p>
          <p>
            7.2 The Influencer grants for each Post:
          </p>
          <p>
            a. Social Collabz and its entitled successors the right to modify and reformat
            the Post making it usable on the Social Collabz platform and/or on the
            means of communication deemed suitable by Social Collabz;
          </p>
          <p>
            b. to Social Collabz and its entitled successors:
          </p>
          <p>
            i. a <em>royalty-free</em>, perpetual, worldwide, irrevocable,
            unconditional, exclusive, transferable license to use the Post for    <em>marketing</em> and promotion purposes of Social Collabz (and its products
            and services) in any manner, without further notice or permission, and
            further fees;
          </p>
          <p>
            ii.the right to repost the Post and to transfer it to third parties by
            paying the Influencer fair compensation;
          </p>
          <p>
            iii.the right to use the <strong>Influencer's Identity</strong> in the Post
            and to communicate the Post to the public in any language, on all<em>social</em> media channels including but not limited to: i) all paid    <em>online media</em> channels (including but not limited to digital
            banners); ii) all <em>proprietary online media</em> channels (including but
            not limited to the <em>website</em> and Social Collabz's international
            communication channels); iii) all social media and/or social networks
            (including but not limited to Social Collabz's <em>social media</em> channels);
            iv) any space assigned on media and/or through public relations activities
            of third parties (including, but not limited to, press, digital media
            and/or <em>social media</em>).
          </p>
          <p>
            c. the Brand and its entitled assignees:
          </p>
          <p>
            i.the right to share, comment and repost the Post on social media channels
            and/or <em>social networks</em> on which it was published, for a period of
            thirty (30) days expiring at 23:59 on the seventh day following the first
            publication;
          </p>
          <p>
            ii.the right to avail the Influencer's Identity in the Post and to
            communicate the Post to the public in accordance with these GTCI and on any
            assigned space in the media and/or through third-party public relations
            activities (including, but not limited to, print media, digital media,
            social <em>media</em> and/or<em> social networks</em>).
          </p>
          <p>
            7.3 The Influencer agrees not to request the Brand to remove the Post
            and/or comments, shares, and/or <em>reposts</em> as provided for in the
            GTCI.
          </p>
          <p>
            7.4 The Influencer allows the Brand and its entitled successors, licensees,
            and/or assignees, and anyone authorized by the Brand, to make the changes
            deemed appropriate to the Post.
          </p>
          <p>
            7.5 In the event of a violation of individual prohibitions and/or
            non-compliance with the individual obligations referred to in the previous
            points, Social Collabz reserves the right to suspend and/or exclude the
            Influencer from the Social Collabz platform.
          </p>
          <p>
            <strong>8. </strong>
            <strong>INFLUENCER </strong>
            COMMITMENTS<strong> AND GUARANTEES</strong><strong></strong>
          </p>
          <p>
            8.1 The Influencer commits:
          </p>
          <p>
            a. not to delay the publication of the Post after the approval of the Brand
            and to publish the approved Post no later than forty-eight (48) hours after
            receiving notification of approval from the Brand (unless a different
            publication date has been agreed with the Brand);
          </p>
          <p>
            b. for a period of five (5) hours after the Post is published, not to
            publish, share any content, send <em>re-tweets</em> or <em>re-grams</em>,
            or <em>repost</em> any content on the Influencer Channel used for
            publication, to ensure the visibility of the Post compared to others
            content within the indicated time period;
          </p>
          <p>
            c. not to remove the Post from the Social Influencer Channels for a period
            of thirty (30) days following the publication of the Post, expiring at
            23:59 on the seventh calendar day following the publication of the Post;
          </p>
          <p>
            d. not to modify the approved Post before or after its publication in ways
            other than those provided for in the GTCI;
          </p>
          <p>
            e. not to perform parodies, not to denigrate the Brand in any way, as well
            as to omit any comment contrary to the Brand and/or its products and/or
            services in general, in any way;
          </p>
          <p>
            f. also through different Social Influencer Channels or on other social<em>networks</em> and/or <em>social media</em>, not to publish other    <em>posts</em> or other content that may reduce the effect of the
            publication of the Post approved by the Brand;
          </p>
          <p>
            g. not to grant any further rights of the Post to a Brand without the
            written consent of Social Collabz, which will charge the Influencer a service
            fee for negotiating any extension of the use of the Post in favor of the
            Brand;
          </p>
          <p>
            h. not to accept or attempt to negotiate with a Brand the execution of a
            number of posts higher than one against the recognition of a single Post
            fee and/or a flat rate and/or unitary fee.
          </p>
          <p>
            8.2 The Influencer acknowledges that the commitments set out in the
            previous point are reasonable in scope and duration and that they have also
            been provided for in compliance with the interests of the Brand and
            Social Collabz.
          </p>
          <p>
            8.3 The Influencer will comply with all the commitments undertaken and, in
            complete freedom, will submit to Social Collabz and the Brand its Posts as an
            independent operator and not as a <em>partner</em>, employee, agent, or
            assuming any other role and/or task.
          </p>
          <p>
            8.4 The Influencer agrees that Social Collabz and the Brand will not be obliged
            to pay him/her and/or to charge him/her any sum in relation to the fact
            that the Influencer is not an employee of Social Collabz and/or the Brand, even
            regards, by way of example but not limited to, holidays, pension funds,
            withholding tax, income tax, workers' compensation contributions or any
            payment or deduction. The Influencer also declares and acknowledges that
            any tax relating to the fees accrued in connection with the GTCI will be
            entirely borne by him/her, relieving Social Collabz of any inherent and
            consequential liability.
          </p>
          <p>
            8.5 The Influencer waives to claim and/or assert that any depiction,
            representation, impersonation, and/or description of the Influencer in the
            Posts may constitute a violation of any of its rights, including the right
            to <em>privacy</em> and/or image, regardless of whether such
            representations, representations, impersonations, descriptions are
            performed by the Brand, by Social Collabz or by their entitled successors.
          </p>
          <p>
            8.6 The Influencer acknowledges that Social Collabz does not guarantee in any
            way the success of a Post and/or the fact that a Post is approved by a
            Brand.
          </p>
          <p>
            <a name="_Hlk5205796">
              8.7 The Influencer confirms that he/she will not negotiate, even
              through legitimate assignees
            </a>
            and/or other third parties, agreements and/or payments with a Brand outside
            the Social Collabz platform.
          </p>
          <p>
            8.8 In the event of a violation of individual prohibitions and/or
            non-compliance with the individual obligations referred to in the previous
            points, Social Collabz reserves the right to suspend and/or exclude the
            Influencer from the Social Collabz platform.
          </p>
          <p>
            <a name="_Hlk5205465">
              <strong>9. </strong>
              <strong>INTELLECTUAL PROPERTY</strong>
            </a>
            <strong></strong>
          </p>
          <p>
            9.1 All material on the Social Collabz platform is the exclusive property
            of Social Collabz and is protected by <em>copyright</em> and trademark law, as
            it cannot be used, except for the functions granted to the Influencer and
            the Brand according to the provisions of the GTCI and the GTCB.
          </p>
          <p>
            9.2 The right granted in the GTCI and the GTCB to the Influencer and the
            Brand to use the functionalities of the Social Collabz platform does not
            entitle any right to use them outside of these provisions.
          </p>
          <p>
            9.3 All rights, titles, and interests in all trademarks, logos, images,
            buttons, codes, <em>layouts</em>, texts, content, products, and services of
            Social Collabz on the Social Collabz platform (the "    <strong>Social Collabz platform Elements</strong>") are the exclusive
            property of Social Collabz and will remain the exclusive property of Social Collabz
            at all times.
          </p>
          <p>
            9.4 Social Collabz may grant a limited and non-exclusive license to use the
            Elements of the Social Collabz platform or some of them to the extent that
            it deems such concession functional to the use of the Social Collabz
            platform.
          </p>
          <p>
            9.5 In the event of a violation of individual prohibitions and/or
            non-compliance with the individual obligations referred to in the previous
            points, Social Collabz reserves the right to suspend and/or exclude the Brand
            from the Social Collabz platform.
          </p>
          <p>
            <strong>10. </strong>
            <strong>ADDITIONAL INFLUENCER AND BRAND</strong>
            STATEMENTS<strong></strong>
          </p>
          <p>
            10.1 The Influencer and the Brands operating on the Social Collabz platform
            guarantee, declare and agree that:
          </p>
          <p>
            a. all information provided to Social Collabz when creating the Influencer
            Account and Brand Account is true, accurate, complete, and not misleading;
          </p>
          <p>
            b. any other account on social <em>media</em> and/or    <em>third party social networks</em> to which they are subscribed and/or
            connected is their own <em>account</em>;
          </p>
          <p>
            c. if the Account refers to a Brand, that account is owned by the
            authorized representative of the Brand with the right to access its social
            media accounts and represent its interests;
          </p>
          <p>
            d. if the <em>account</em> refers to another subject, specific
            authorization has been made, also concerning access to <em>accounts</em> on
            social <em>media</em> and/or <em>social networks</em> representing its
            interest;
          </p>
          <p>
            e. they have the right and/or authorization to create an Influencer Account
            or a Brand Account and accept the GTCI and the GTCB;
          </p>
          <p>
            f. they are authorized to send material to the Social Collabz platform in
            accordance with the GTCI and the GTCB;
          </p>
          <p>
            g. all material sent to the Social Collabz platform will not have content
            that:
          </p>
          <p>
            i.is illegal and/or violates applicable regulatory requirements of any
            degree or level;
          </p>
          <p>
            ii.is copied and/or adapted in whole and/or substantially from other
            material;
          </p>
          <p>
            iii.is indecent, obscene, threatening, discriminatory, harassing, violates
            security, in breach of any confidence, defamatory, offensive or
            objectionable or contains, depicts or alludes to or promotes aggressive,
            unruly, antisocial, lewd or illegal behavior or sexually suggestive
            imagery;
          </p>
          <p>
            iv.parodies disparages and/or makes fun of Social Collabz and/or its products
            and/or services in any other way;
          </p>
          <p>
            v.requests<em> password</em> and/or personal information for commercial
            and/or illicit purposes or provides any telephone number, postal address,
            surname, URL, or <em>email</em> address (unless the presence of the
            indicated elements, as regards the only lawful ones, is expressly requested
            in the Campaign);
          </p>
          <p>
            vi.promotes illegal and/or unauthorized of another person’s copyrighted
            work (such as but not limited to, providing pirated <em>computer</em>
            programs or <em>links</em> to them, providing information to circumvent
            protected devices, or providing pirated material);
          </p>
          <p>
            vii.includes the transmission of junk mail, chain letters, bulk sending of
            junk mail or <em>spam</em>;
          </p>
          <p>
            viii.violates the <em>privacy</em>, contractual rights, and/or other rights
            of any person (including Intellectual Property Rights).
          </p>
          <p>
            10.2 In the event of a violation of individual prohibitions and/or
            non-compliance with the individual obligations referred to in the previous
            points, Social Collabz reserves the right to suspend and/or exclude the Brand
            from the Social Collabz platform.
          </p>
          <p>
            <strong>11. </strong>
            <strong>
              PROVISIONS ON THE RELATIONSHIP BETWEEN INFLUENCERS AND BRANDS
            </strong>
            <strong></strong>
          </p>
          <p>
            11.1 the Influencer and the Brand acknowledge that:
          </p>
          <p>
            a. neither the Influencer nor Social Collabz is required to purchase any of the
            Brand’s products or services;
          </p>
          <p>
            b. Brand may, at its sole discretion, arrange to send an Influencer a
            sample product;
          </p>
          <p>
            c. the Influencer can ask the Brand to send a sample product, but the Brand
            is under no obligation to do so;
          </p>
          <p>
            d. Social Collabz will not be responsible in any way for delays in delivery
            and/or non-delivery of products sent by the Brand to the Influencer;
          </p>
          <p>
            e. the address provided by the Influencer via the Social Collabz platform
            is not verified by Social Collabz;
          </p>
          <p>
            f. if the Brand sends a sample product to the Influencer, there is no
            guarantee that the Influencer will make a post about that product or that
            any Post submitted will be positively evaluated by the Brand;
          </p>
          <p>
            g. if the Influencer chooses to purchase a product, there is no guarantee
            that the Influencer's post about the product will be approved by the Brand;
          </p>
          <p>
            h. the GTCB and the GTCI do not provide for any right in favor of the Brand
            on the Intellectual Property Rights on the Posts and/or on the Identity of
            the Influencer;
          </p>
          <p>
            i. the GTCB and the GTCI do not provide for any property rights in favor of
            the Influencer on the Brand's Intellectual Property Rights;
          </p>
          <p>
            j. the GTCB and the GTCI do not provide that the Brand must make use of the
            rights granted to the Brand by the Influencer;
          </p>
          <p>
            k. that the Brand and the Influencer agree to send drafts and/or samples,
            the related reports must be executed exclusively through the Social Collabz
            platform.
          </p>
          <p>
            11.2In the event of a violation of individual prohibitions and/or
            non-compliance with the individual obligations referred to in the previous
            points, Social Collabz reserves the right to suspend and/or exclude the Brand
            from the Social Collabz platform
          </p>
          <p>
            <strong>12. </strong>
            <strong>
              GUARANTEES AND INDEMNITIES OF INFLUENCERS AND BRANDS IN FAVOR OF
              SOCIAL COLLABZ
            </strong>
            <strong></strong>
          </p>
          <p>
            12.1 The Influencer and the Brand agree to indemnify Social Collabz and its
            member bodies, its staff, its officers, and its agents from and against any
            type of claim, liability, damage, loss, and expense arising out of or
            connected in any way to the following ( including as a result of your
            direct activities on the Social Collabz platform):
          </p>
          <p>
            a. the materials uploaded to the Social Collabz platform and/or access to
            it;
          </p>
          <p>
            b. the violation of the GTCI and the GTCB and/or any guarantee made and/or
            stipulated agreement;
          </p>
          <p>
            c. claims against a Brand for any reason;
          </p>
          <p>
            d. claims against an Influencer for any reason;
          </p>
          <p>
            e. any claim by third parties (including a different Brand and/or
            Influencer) arising directly and/or indirectly from the breach of any
            provision of the GTCI and/or the GTCB;
          </p>
          <p>
            f. any claim also concerning the fact that the materials uploaded to the
            Social Collabz platform violate the rights of third parties, including
            Intellectual Property Rights;
          </p>
          <p>
            g. the violation of any applicable legislation.
          </p>
          <p>
            12.2 In the event of a violation of individual prohibitions and/or
            non-compliance with the individual obligations referred to in the previous
            points, Social Collabz reserves the right to suspend and/or exclude the Brand
            from the Social Collabz platform.
          </p>
          <p>
            <strong>13. </strong>
            <strong>LIMITATION OF LIABILITY IN FAVOR OF SOCIAL COLLABZ</strong>
            <strong></strong>
          </p>
          <p>
            <a name="_Hlk5216759">
              13.1 By using the Social Collabz platform, the Influencer and/or
            </a>
            Brand may be exposed to content that presents characteristics of
            dangerousness, obscenity, deceptiveness, and/or neglect. Under no
            circumstances will Social Collabz be liable in any way for any Content,
            including but not limited to any errors or omissions in such Content or any
            loss or damage of any kind incurred as a result of any use of Content
            posted, transmitted, or otherwise made available via the Social Collabz
            platform.
          </p>
          <p>
            13.2 Social Collabz's liability to the Brand about the GTCI and the GTCB will be
            limited to the amount paid by the Brand to Social Collabz for services related
            to the most recent Advertising Campaign. Social Collabz shall not be liable to
            Influencers for damages of any kind arising from the use of the Social
            Collabz platform. In no event shall Social Collabz and/or any of its associated
            entities, successors in title, licensee, and/or licensor be liable for any
            direct, indirect, special, incidental, consequential, punitive, and/or
            exemplary damages arising out of and/or in connection with the Social
            Collabz platform, the GTCI and/or the GTCB and/ or any Posts, material
            uploaded into the Social Collabz platform and/or Campaign. The foregoing
            limitations apply whether the alleged liability is based on tort, contract,
            negligence, or any other basis, even if Social Collabz or any other party has
            been advised of the possibility of such damages.
          </p>
          <p>
            13.3 Social Collabz makes no warranties and assumes no commitment that the
            Social Collabz platform is always available and/or is always error-free,
            reliable and/or safe, and/or free of viruses and/or other dangerous
            components. The Influencer and the Brand use the Social Collabz platform
            assuming the inherent and consequent risks, accepting that Social Collabz
            disclaims all responsibility for damages and/or losses that may occur,
            regardless of whether they are direct or consequent. The Influencer and the
            Brand accept that Social Collabz disclaims any responsibility for losses
            resulting from any interference, malfunction, delay, failure, and/or damage
            that may occur to the device used and/or that derive from the access,
            installation, updating, and/or use of the Social Collabz platform. The
            Influencer and the Brand assume responsibility for the use of the Social
            Collabz platform, including compliance with all applicable laws and
            regulations.
          </p>
          <p>
            13.4 In the event of a violation of individual prohibitions and/or
            non-compliance with the individual obligations referred to in the previous
            points, Social Collabz reserves the right to suspend and/or exclude the Brand
            from the Social Collabz platform.
          </p>
          <p>
            <strong>14. </strong>
            <strong>MONITORING</strong>
            <strong></strong>
          </p>
          <p>
            14.1 Social Collabz reserves the right to monitor the Social Collabz platform
            and all activities of Influencer Accounts and Brand Accounts.
          </p>
          <p>
            14.2 If in the execution of this monitoring activity Social Collabz finds signs
            of any kind of fraud, abuse, or suspicious activity, Social Collabz may at its
            discretion delete or suspend the Influencer Account and/or the Brand
            Account that was the author or participated in violation.
          </p>
          <p>
            14.3 Social Collabz retains the right to remove material uploaded to the Social
            Collabz platform that it considers being a violation of the GTCI and/or the
            GTCB.
          </p>
          <p>
            14.4 Social Collabz reserves the right, in all cases regulated by the previous
            point, to take action for damages against the owner of the Influencer
            Account and/or the Brand Account.
          </p>
          <p>
            <strong>15. </strong>
            <strong>FORCE MAJEURE</strong>
            <strong></strong>
          </p>
          <p>
            15.1 If Social Collabz platform is not able to function for any reason
            or/or event beyond the possibilities of repair and/or maintenance by
            Social Collabz, Social Collabz reserves the right to provide, at its discretion, to
            cancel, terminate, modify or suspend access to it.
          </p>
          <p>
            15.2 Reasons for force majeure are acts of war, terrorism, states of
            emergency or disaster (including natural disasters), computer virus
            infections, <em>bugs</em>, tampering, unauthorized interventions, technical
            failures, and/or any event that may significantly affect the
            administration, security, integrity and/or proper functioning of the Social
            Collabz platform.
          </p>
          <p>
            <strong>16. </strong>
            <strong>EFFECTIVENESS OF GTCI AND GTCB</strong>
            <strong></strong>
          </p>
          <p>
            16.1 The GTCI and the GTCB prevail over any other agreement entered into by
            Social Collabz, the Influencer, and/or the Brand.
          </p>
          <p>
            16.2 If one or more clauses of the GTCI and/or GTCB are considered null
            and/or voidable and/or invalid and/or e unenforceable and/or ineffective,
            this circumstance will not affect the validity of the other separate
            clauses, which must be considered fully in force and effect.
          </p>
          <p>
            <strong>17. APPLICABLE LAW AND EXCLUSIVE JURISDICTION</strong>
            <strong></strong>
          </p>
          <p>
            17.1 The GTCI and the GCCB are governed by Italian law and it is expressly
            agreed that the Court of Milan has exclusive jurisdiction for any dispute
            arising from them, also concerning their validity, interpretation, and
            execution.
          </p>
          <p>
            <strong>18. COMMUNICATIONS</strong>
            <strong></strong>
          </p>
          <p>
            18.1 For the execution of the GTCI and the GTCB, for any communication with
            the Influencer and the Brand, Social Collabz provides the following addresses:
            i) for communications by post: Piazzale Biancamano 8, 20121 Milan (MI); ii)
            for communications by email: info@socialcollabz.com.
          </p>
          <p>
            <strong>19. </strong>
            <strong>INTERPRETATION AND DEFINITIONS</strong>
          </p>
          <p>
            19.1 In the GTCI and the GTCB:
          </p>
          <p>
            a. words that indicate a gender also include all other genders;
          </p>
          <p>
            b. the singular includes the plural and vice versa;
          </p>
          <p>
            c. a term derived from another defined term has a corresponding meaning;
          </p>
          <p>
            d. any reference to a natural person includes his or her representatives,
            successors, and permitted assigns;
          </p>
          <p>
            e. Monetary amounts are expressed in Australian dollars or euros.
          </p>
          <p>
            <a name="_Hlk5111873">19.2 </a>
            The following terms have the meanings in the GTCI and the GTCB
            respectively:<a name="_Hlk5111895"></a>
          </p>
          <p>
            a. "Brand <strong>Account</strong>" means the registration <em>account</em>
            on the Social Collabz platform created and used by the Brand;
          </p>
          <p>
            b. "Influencer <strong>Account</strong>" means the registration    <em>account</em> on the Social Collabz platform created and used by the
            Influencer;
          </p>
          <p>
            <a name="_Hlk5220713">
              c. "<strong>Media Agency</strong>" means any person who carries out the
              activity of social <em>networks</em> and/or <em>social media</em>;
            </a>
          </p>
          <p>
            d. "<strong>Social Collabz App</strong>" means the application through which the
            Influencer accesses the use of the Social Collabz platform, available for    <em>download</em> on https://www.apple.com/itunes/ and Google Play;
          </p>
          <p>
            e. "<strong>Brand</strong>" means each entity that uses the Social Collabz
            platform to create an Advertising Campaign;
          </p>
          <p>
            f. "Campaign <strong>Budget</strong>" means the amount of money that the
            Brand dedicates to the realization of the Advertising Campaign;
          </p>
          <p>
            g. "Advertising <strong>Campaign</strong>" means the Advertising Campaign
            commissioned by the Brand to the Influencer via the Social Collabz
            platform;
          </p>
          <p>
            h. "Campaign <strong>Builder</strong>" means the <em>desktop</em> platform
            that the Brand uses to create an Advertising Campaign to be uploaded to the
            Social Collabz platform and is available for <em>download</em> on
            https://socialcollabz.com;
          </p>
          <p>
            i. "<strong>Social Influencer Channels</strong>" means the social    <em>media</em> and/or <em>social network</em> channels of an Influencer,
            such as Twitter, Facebook, or Instagram;
          </p>
          <p>
            j. "<strong>GTCB"</strong> means the General Terms and Conditions of Brand;
          </p>
          <p>
            k. "<strong>GTCI"</strong> means the General Terms and Conditions of
            Influencer Contract;
          </p>
          <p>
            l. "<strong>Post</strong> <strong>Fee</strong>" means the economic
            consideration recognized by the Brand to the Influencer following the
            approval of the Post and its publication;
          </p>
          <p>
            m. "<strong>Influencer</strong> <strong>Community</strong>" means an
            Influencer's followers on its Social Influencer Channels;
          </p>
          <p>
            n. "<strong>Content Right</strong>" means the procedure provided by the
            Social Collabz platform with which the Influencer sells to the Brand the
            rights of the Post subject to the approval procedure;
          </p>
          <p>
            o. "<strong>Intellectual Property Rights</strong>" means all current or
            future intellectual property rights of any nature exercisable anywhere in
            the world including, but not limited to, rights relating to or in
            connection with <em>copyrights</em>, inventions (including patents),
            trademarks, service marks, trade names, domain names, <em>designs</em>. ,
            any rights related to the topography of silicon chips, confidential
            information, <em>know-how</em> and similar, industrial and commercial
            intellectual property rights, whether or not registrable or registered,
            including the right to request the registration of such rights;
          </p>
          <p>
            p. "<strong>Elements of the Social Collabz platform</strong>" means all
            rights, titles, and interests in and to all trademarks, logos, images,
            buttons, codes, <em>layouts</em>, texts, content, products, and services
            owned exclusively by Social Collabz and present on the Social Collabz platform;
          </p>
          <p>
            q. "<strong>Social Collabz</strong>" means Social Collabz GmbH., tax code, and
            registration number in the register of companies of
            Zug, Switzlerand, with registered
            office in via Nordstrasse 1, 6300 Zug, Switzlerand, CH499205145;
          </p>
          <p>
            r. "<strong>Influencer Identity</strong>" means    <strong>the Influencer's</strong> name, image, a distinctive sign,
            character, and online character ;
          </p>
          <p>
            s. "<strong>Influencer</strong>" means a <em>social media influencer</em>
            who registers on the Social Collabz platform to create Posts and receive
            compensation from Brands through the same;
          </p>
          <p>
            t. "<strong>Social Collabz platform</strong>" means the Social Collabz App, the
            Campaign Builder, and any other functionality and/or <em>website</em> owned
            and/or associated with Social Collabz;
          </p>
          <p>
            u. "<strong>Post</strong>" means a <em>social media</em> and/or    <em>social network</em> <em>post</em> created by an Influencer and to be
            shared with the Influencer Community via the Social Influencer Channels;
          </p>
          <p>
            v. "<strong>Linked Sites</strong>" means the <em>websites</em> accessible
            via certain features of the Social Collabz platform;
          </p>
          <p>
            w. "<strong>Connected</strong> Socials" means <em>social media</em> and/or    <em>social networks</em> accessible via certain features of the Social
            Collabz platform;
          </p>
          <p>
            x. "<strong>Wallet</strong>" means the functionality of the Social Collabz
            platform through which the Brand allocates a certain <em>budget</em> for a
            given activity.
          </p>
        </div>
      </div>
    </div>
  </section>
</main>

<main *ngIf='pages["terms-brand"]'>
  <section class="article black-bg">
    <div class="wrapper">
      <h1>Terms & Conditions</h1>
      <div class="article__wrapper">
        <div class="article__content">
          <p class="h1">GENERAL TERMS AND CONDITIONS OF BRAND CONTRACT</p>
          <p>
            <strong>1. </strong>
            <strong>THE SOCIAL COLLABZ PLATFORM</strong>
            <strong></strong>
          </p>
          <p>
            1.1 The Social Collabz Platform (www.socialcollabz.com) is owned by
            Social Collabz GmbH, tax code and registration number in the register of
            companies of Nordstrasse 1, 6300 Zug, Switzlerand, CH499205145, with
            registered office in Nordstrasse 1, 6300 Zug, Switzlerand ("    <strong>Social Collabz</strong>").
          </p>
          <p>
            1.2Social Collabz has created the Social Collabz Platform to allow the    <strong>Brand</strong> and the Influencer to interact, establish
            relationships and create and distribute sponsored <strong>posts</strong>
            through the Social Influencer <strong>Channels</strong> in the    <strong>Influencer Community</strong>.<strong></strong>
          </p>
          <p>
            1.3 These General Terms and Conditions of Brand Contract (the "    <strong>GTCB</strong>") explain how to access and use the Social Collabz
            Platform, fully accepted by joining and/or using the platform by the Brand.
          </p>
          <p>
            1.4 The contractual position of the Influencer is governed by the General
            Terms and Conditions of Influencer ("<strong>GTCI</strong>"), which are
            fully accepted by the Influencer each time the Influencer uses the Social
            Collabz Platform.
          </p>
          <p>
            <a name="_Hlk118379238">1.5 Upon the approval of the GTCI and the </a>
            GTCB, Social Collabz grants the Influencer and the Brand a limited,
            non-exclusive, revocable and non-transferable license to access and use the
            Social Collabz Platform.
          </p>
          <p>
            <a name="_Hlk118379496">1.6 The acceptance of the GTCI and the </a>
            GTCB does not determine between the Influencer, the Brand and Social Collabz any
            relationship other than that of use of the Social Collabz Platform as
            described and, in particular, for purely illustrative but not exhaustive
            purposes, it does not determine any <em>partnership</em> or agency,
            employment, fiduciary relationship.
          </p>
          <p>
            1.7 Access and/or use of the Social Collabz Platform is subject to
            acceptance of the GTCI by the Influencer and the GTCB by the Brand.
          </p>
          <p>
            1.8 Each time the Influencer accesses and uses the Social Collabz Platform,
            the Influencer agrees to comply with the GTCI. If the Influencer does not
            wish to comply with the GTCI, the Influencer must not access and/or use the
            Social Collabz Platform.
          </p>
          <p>
            1.9 Each time the Brand accesses and uses the Social Collabz Platform, it
            agrees to comply with the GTCB. If the Brand does not intend to comply with
            the GTCB, it must not access and/or use the Social Collabz Platform.
          </p>
          <p>
            1.10 The Social Collabz Platform allows the Brand to allocate a<strong>Campaign Budget</strong> to create an <strong>Advertising</strong>    <strong>Campaign</strong> using the <strong>Campaign Builder</strong>
            functionality to share it with the Influencer.
          </p>
          <p>
            1.11 The Influencer will access the Advertising Campaign through the    <strong>Social Collabz</strong><strong> App</strong>, creating and sending
            a Post to the Brand for approval, it is understood that the Brand may
            refuse it or request modifications.
          </p>
          <p>
            1.12 The Influencer may proceed with the agreed Post's publication in case
            of the Brand's approval, thus executing the Advertising Campaign.
          </p>
          <p>
            1.13 The Influencer will receive the <strong>Post</strong>    <strong>Fee</strong> only in case of approval of the Post and after the
            publication of the post through the Social Influencer Channels.
          </p>
          <p>
            1.14 The Social Collabz Platform is designed for use by people aged
            eighteen (18) years and older. Influencers and Brands are solely
            responsible for each interaction on the Social Collabz Platform.
            <a name="_Hlk5442408">
              If Social Collabz becomes aware of the access of a person under eighteen
              (18) years of age
            </a>
            to the Social Collabz Platform, it will proceed to exclude that person from
            the platform.
          </p>
          <p>
            1.15 Social Collabz reserves the right to modify the GTCB at any time by
            updating the relevant website and notifying the Brand by sending a
            communication to the <em>e-mail</em> address indicated in the    <strong>Brand</strong><strong> </strong><strong>Account</strong>.
          </p>
          <p>
            1.16 Social Collabz reserves the right to intervene on the Social Collabz
            platform by making necessary and/or appropriate changes and for maintenance
            purposes, notifying the Brand using the <em>e-mail</em> address indicated
            in the Brand Account. During the interventions, access to and use of the
            Social Collabz platform may be temporarily suspended, it is understood that
            Social Collabz must provide specific notice at least twenty-four (24) hours
            before, by using the <em>email</em> address indicated in the Influencer
            Account and/or <strong>Brand Account.</strong>
          </p>
          <p>
            1.17 Social Collabz disclaims any responsibility for the process of creating the
            Advertising Campaign, publishing the Post, and paying the Post Fee, in
            consideration of the fact that all these phases are managed exclusively by
            the Influencer and the Brand in complete autonomy.
          </p>
          <p>
            1.18 Social Collabz disclaims any responsibility regarding the material uploaded
            on the Social Collabz platform by the Influencer and/or by the Brand, as it
            does not assume any commitment to moderate, approve, monitor, modify,
            remove the content, nor to verify its legality, validity, accuracy,
            adequacy.
          </p>
          <p>
            1.19 The Influencer and the Brand will not be able to negotiate the
            execution and/or payment of an Advertising Campaign outside the Social
            Collabz platform.
          </p>
          <p>
            1.20 The Influencer and the Brand are committed: i) not to decompile,    <em>reverse engineer</em>, disassemble in any way any element of the Social
            Collabz platform, including through third parties; ii) not to distribute or
            republish any element of the Social Collabz platform in any way; iii) not
            to resell, rent, or allow the use, even temporary, of any element of the
            Social Collabz platform in any way; iv) not to tamper with, disable or
            circumvent any security feature of the Social Collabz platform in any way;
            v) not to transfer any element of the Social Collabz platform to any third
            party in any way; vi) to guarantee access by Social Collabz to information
            relating to location and geolocation; vii) to guarantee Social Collabz the
            possibility of using the respective logo about the services and/or products
            provided.
          </p>
          <p>
            1.21 The Influencer and the Brand agree not to participate in any    <em>crawling</em>, <em>scraping</em>,<em> or caching</em> activity, not to
            access in any way any content of the Social Collabz platform through any
            means, except by the express authorization of Social Collabz, which will be
            communicated in writing via the Social Collabz platform upon request
            submitted through the platform.
          </p>
          <p>
            1.22 The Social Collabz platform may contain <em>links</em> to other sites
            not operated by Social Collabz ("<strong>Linked Sites</strong>"). Social Collabz
            disclaims all responsibility for the content of the Linked Sites, their
            quality, and the nature of the products or services offered, used, or
            advertised by them. Social Collabz also disclaims all responsibility for any
            collection and/or use of data and/or information that may be performed by
            the Linked Sites. The Brand and the Influencer assume all responsibility
            for access to and/or use of the Linked Sites.
          </p>
          <p>
            1.23 The Social Collabz platform may also use social <em>networks</em> or
            share some features and may contain <em>links</em>, applications, or other    <em>social media</em> features (including but not limited to Facebook,
            Twitter, and Instagram; the "<strong>Connected Socials</strong>"). The
            Influencer and the Brand accept the conditions of use provided by the
            Connected Social Networks used by the Social Collabz platform. The
            Influencer and the Brand also allow the Social Collabz platform to access
            all the information shared on the Connected Social Networks. Social Collabz
            suggests that you check the terms and conditions of use of the Social
            Networks Connected to the Social Collabz platform before selecting the
            relevant <em>links</em>, applications, or functions and/or interacting with
            them. The Social Collabz platform is in no way sponsored, subscribed,
            administered, or associated with any of the Linked Social Networks.
          </p>
          <p>
            1.24 All costs arising from the <em>download</em>, installation, access,
            and use of the Social Collabz platform are borne by the Brand, which is
            solely responsible for the use of the Social Collabz platform.
          </p>
          <p>
            1.25 The Influencer and the Brand must verify and ensure that the devices
            used are compatible with the Social Collabz platform and that they comply
            with all the technical specifications necessary for access and use of the
            platform. Social Collabz disclaims any responsibility for the accessibility to
            the Social Collabz platform about the connection service chosen by the
            Brand and/or the devices used.
          </p>
          <p>
            <a name="_Hlk5200540">1.26 In the event of a violation of</a>
            the individual prohibitions and/or non-compliance with the individual
            obligations referred to in the previous points, Social Collabz reserves the
            right to suspend and/or exclude the Brand from the Social Collabz platform.
          </p>
          <p>
            <a name="_Hlk5220832">
              1.27 The provisions of these GTCB relating to Brands also apply to        <strong>Media Agencies</strong>.
            </a>
          </p>
          <p>
            <strong>2. </strong>
            <strong>BRAND</strong>
            ACCOUNT REGISTRATION<strong></strong>
          </p>
          <p>
            <a name="_Hlk118379664">
              2.1 To use the Social Collabz platform, the Brand must download
            </a>
            the Campaign Builder and register its Brand Account, as provided for in the
            GTCB.
          </p>
          <p>
            2.2 Social Collabz reserves the right to refuse registration and/or cancel the
            Brand Account at its sole discretion.
          </p>
          <p>
            2.3 All information provided when registering for the Brand Account must be
            current, correct, and complete. Incomplete, unsuitable, and/or
            incomprehensible registrations will not be considered valid. You must    <em>log in</em> in the manner required each time you wish to use the Social
            Collabz platform and it is forbidden to use another person's Brand Account
            without their prior consent.
          </p>
          <p>
            2.4 The Brand is required to maintain the strictest confidentiality
            regarding the details of its Brand Account and any activity carried out on
            the Social Collabz platform.
          </p>
          <p>
            2.5 The Brand agrees to notify Social Collabz immediately of any unauthorized
            use of its Brand Account or any other breach of security. Access to and use
            of the Brand Account is the sole responsibility of the Brand, which may
            notify the cancellation at any time.
          </p>
          <p>
            2.6 Social Collabz shall not be liable for any liability and/or damage suffered
            by the Brand in connection with non-compliance with the GTCB.
          </p>
          <p>
            2.7 Social Collabz reserves the right to delete the Brand Account and/or to
            prohibit the Brand from accessing the Social Collabz platform (or any
            element thereof) if it considers that the Brand: i) is making use of the
            platform not in accordance with the GTCB; ii) intends to tamper in any way
            with the platform (or any of its elements); iii) has violated the GTCB
            and/or has been involved in any illegal and/or illegal activity or, in any
            case, incorrect as it is aimed at endangering the normal management of the
            Social Collabz platform (or any of its elements).
          </p>
          <p>
            2.8 It is strictly forbidden the use any automated <em>software</em> or
            other mechanical and/or electronic means that allow the creation of one or
            more Brand Accounts. Social Collabz reserves the right to suspend or delete any
            Brand Account created in violation of this provision.
          </p>
          <p>
            2.9 The information that will be provided by the Brand for the creation of
            the Brand Account will be addressed only to Social Collabz and the Brand will be
            responsible for their correctness.
          </p>
          <p>
            2.10 The Brand expressly indemnifies Social Collabz, the Linked Social Networks,
            and/or the Linked Sites in relation to the consequences of any loss,
            action, claim, cost, expense, and damage (of any nature) that may arise in
            the use of the Social Collabz platform.
          </p>
          <p>
            2.11 Any questions, comments, and/or complaints concerning the Social
            Collabz platform must be addressed exclusively to Social Collabz and may not be
            transmitted to other parties and/or Linked Social Networks.
          </p>
          <p>
            2.12 In the event of a violation of individual prohibitions and/or
            non-compliance with the individual obligations referred to in the previous
            points, Social Collabz reserves the right to suspend and/or exclude the Brand
            from the Social Collabz platform.
          </p>
          <p>
            <strong>3. CAMPAIGN BUILDER DOWNLOADS AND BRAND ENGAGEMENTS</strong>
            <strong></strong>
          </p>
          <p>
            3.1 To register a Brand Account, the Brand must: i) download the Campaign
            Builder and <em>login</em> as required by the Social Collabz platform; ii)
            communicate all the requested data, such as, by way of example and not
            exhaustively, the full name and/or company name and/or full company name,    <em>email</em> address, telephone number, and the <em>password</em>; iii)
            select the chosen payment method (bank transfer or debit to the current
            account and/or credit card); iv) provide all the details necessary and/or
            useful for the execution of each payment.
          </p>
          <p>
            3.2 No amount will be charged for the creation of the Brand Account.
          </p>
          <p>
            3.3 Social Collabz may, at its sole discretion, implement a minimum spend for
            Brand Accounts.
          </p>
          <p>
            3.4 The Brand, concerning the material uploaded on the Social Collabz
            platform to carry out an Advertising Campaign, agrees to:
          </p>
          <p>
            a. grant Social Collabz and its agents a <em>royalty-free</em>, perpetual,
            worldwide, irrevocable, unconditional, non-exclusive, transferable license
            to use the material published on the Social Collabz platform for marketing
            and promoting Social Collabz (and its products and services) in any manner,
            without further notification to or consent of the Brand or any compensation
            payable to the Brand;
          </p>
          <p>
            b. grant the Influencer a royalty-free, perpetual, international,
            irrevocable, unconditional, non-exclusive, transferable license to post,
            share, and comment on the material published on the Social Collabz platform
            on its Social Influencer Channels as part of the approved Post in
            compliance with the GTCB;
          </p>
          <p>
            c. acknowledges and accepts, i) that the license to use the content
            published on the Social Collabz platform shall only commence upon payment
            of the Post Fees and ii) that it shall not be entitled to use any Content
            or anything similar to the Content in any way outside of the Social Collabz
            platform unless and until it has paid the Post Fees.
          </p>
          <p>
            3.5 Social Collabz has the right to cancel and/or suspend the provision of its
            services and/or to suspend and/or delete the Account Brand if it has
            reasonable grounds to believe that the Brand is not using the Social
            Collabz platform in a lawful and/or correct and/or reasonable manner about
            the purpose and/or function of the Platform as established in the GTCB.
          </p>
          <p>
            <strong>4. </strong>
            <strong>PAYMENTS TERMS FOR BRAND</strong>
            <strong></strong>
          </p>
          <p>
            4.1 The Brand agrees to pay the costs for the use of the Social Collabz
            platform and the Post Fees and the fees agreed, which will be indicated on
            its Brand Account in accordance with the GTCB. The payment of the amounts
            due in accordance with the previous point must be carried out in the manner
            provided by Campaign Builder or according to the written indications
            transmitted by Social Collabz.
          </p>
          <p>
            4.2 Individual payments must be made, in the absence of different written
            indications by Social Collabz, by credit card, PayPal, or other prepaid systems,
            and, in any case, by using a supplier authorized by Social Collabz. Social Collabz
            reserves the right to charge a specific fee when using credit cards.
          </p>
          <p>
            4.3 Social Collabz reserves the right to verify the reliability and effective
            functionality of the means and payment credentials provided by the Brand.
            The Brand guarantees in any case the ownership of the means and payment
            credentials provided and that they are updated, correct, complete, and
            capacious about the commitments undertaken.
          </p>
          <p>
            4.4 The Brand agrees not to use fraudulent and/or illegal means and/or
            payment methods.
          </p>
          <p>
            4.5 In particular, the Brand will allocate the amount allocated to the
            Advertising Campaign using the <strong>Wallet</strong> function through the
            Campaign Builder, before it begins.
          </p>
          <p>
            4.6 Social Collabz reserves the right to perform a specific pre-authorization
            check of the means of payment indicated by the Brand about the spending
            commitment made on the occasion of each Advertising Campaign.
          </p>
          <p>
            4.7 In any case, the possibility of sharing the Advertising Campaign with
            the Influencer is expressly subject to the successful outcome of the
            pre-authorization verification on the means of payment indicated by the
            Brand.
          </p>
          <p>
            4.8 The Brand authorizes Social Collabz to charge the amount due for the Post
            Fee following the publication of the agreed Post by the Influencer through
            the Social Collabz platform.
          </p>
          <p>
            4.9 If a Brand is not based in Australia or Italy or the European Union,
            the invoicing of the fees due will not include, respectively, GST, VAT, or
            IVA, without prejudice to the charge of taxes, fees of any kind as
            applicable due to specific regulatory laws.
          </p>
          <p>
            4.10 In the absence of any other specific provision and/or agreement, the
            Brand shall make payments as soon as the invoice is issued.
          </p>
          <p>
            4.11 Any timing and/or form and/or method of payment other than that
            indicated in the GTCB must be specifically approved by Social Collabz.
          </p>
          <p>
            4.12 Without prejudice to payment obligation assumed if the Brand fails to
            pay any charges within seven (7) business days, Social Collabz reserves the
            right to charge a late payment fee calculated at a rate of ten percent
            (10%) per annum. Social Collabz also reserves the right to suspend and/or
            exclude the defaulting Brand from accessing the Social Collabz platform by
            terminating any ongoing Campaigns.
          </p>
          <p>
            4.13 The Brand is committed to not agreeing with the Influencer forms of
            remuneration other than those established by the Social Collabz platform
            and in particular, by way of example but not limited to, not agreeing on a
            product or service as remuneration to the Influencer. The methods and
            timing of payment of the fees due to Influencers may be subject to
            negotiation through Social Collabz and the Social Collabz platform.
          </p>
          <p>
            4.14 In the case of violation of individual prohibitions and/or
            non-compliance with the individual obligations referred to in the previous
            points, Social Collabz reserves the right to suspend and/or exclude the Brand
            from the Social Collabz platform.
          </p>
          <p>
            <strong>5.</strong>
            <strong> TERMS OF BRAND PARTICIPATION IN THE ADVERTISING</strong>
            CAMPAIGN<strong></strong>
          </p>
          <p>
            5.1 Social Collabz reserves the right to refuse an Advertising Campaign if it
            does not comply with the GTCB.
          </p>
          <p>
            5.2 The Brand confirms that it will not negotiate, even though its
            successors in title or other third parties, agreements and/or payments with
            an Influencer outside the Social Collabz platform.
          </p>
          <p>
            5.3 The Brand agrees not to accept or attempt to negotiate with the
            Influencer the execution of a number of Posts higher than one for the
            recognition of a single Post Fee and/or a flat-rate and/or unitary fee.
          </p>
          <p>
            5.4 The Brand is committed to ensuring the independence of the Influencer,
            regardless of the relationship that derives from the interaction on the
            Social Collabz platform.
          </p>
          <p>
            5.5 The Brand warrants that:
          </p>
          <p>
            a. It owns all <strong>Intellectual Property Rights</strong> of the content
            shared on the Social Collabz platform and it has the right to grant
            licenses on them to Social Collabz and the Influencer in accordance with the
            GTCB;
          </p>
          <p>
            b. It will not share on the Social Collabz platform Content that it knows
            and/or reasonably suspects may be false, misleading, and/or illusory.
          </p>
          <p>
            5.6 The Brand agrees not to oppose exceptions about the recognition of the
            Post Fee due to an Influencer where the Post published is consistent with
            the agreed Advertising Campaign.
          </p>
          <p>
            5.7 Post Fee will be paid to the Influencer within (48) hours after
            receiving payments by the Brand unless the Influencer is responsible for a
            violation of the GTCB, Social Collabz disclaims any inherent and consequent
            liability.
          </p>
          <p>
            5.8 The Brand is entitled to purchase the intellectual rights of the Post
            subject to the approval procedure by the Brand only after the influencer
            has published the content and received the agreed fee through the Social
            Collabz platform.
          </p>
          <p>
            5.9 The Brand agrees to use the rights of the post for uses permitted by
            law and not to damage the image of the influencer in any way.
          </p>
          <p>
            5.10 The Brand accepts that Social Collabz will not be responsible for the
            content of the material shared through the Social Collabz platform, nor for
            the content of the Post.
          </p>
          <p>
            5.11 Social Collabz disclaims all responsibility for the use of the Post by the
            Brand.
          </p>
          <p>
            5.12 The Brand acknowledges that the Influencer is an independent third
            party and that the content of the Post is in no way determined by
            Social Collabz. Consequently, the Brand accepts that the Influencer may publish
            Posts that are not in line with what has been agreed upon in the
            Advertising Campaign. The Brand specifically acknowledges and accepts that
            Social Collabz has no control over any Post that may be published by the
            Influencer, assuming all responsibilities and risks inherent and consequent
            to the collaboration with the Influencer through the Social Collabz
            platform.
          </p>
          <p>
            5.13 The Brand acknowledges that Social Collabz does not provide any guarantee
            of the success of the Advertising Campaign.
          </p>
          <p>
            5.14 In the event of a violation of individual prohibitions and/or
            non-compliance with the individual obligations referred to in the previous
            points, Social Collabz reserves the right to suspend and/or exclude the Brand
            from the Social Collabz platform.
          </p>
          <p>
            <strong>6. </strong>
            <strong>INTELLECTUAL PROPERTY </strong>
            <strong></strong>
          </p>
          <p>
            6.1 All material on the Social Collabz platform is the exclusive property
            of Social Collabz and is protected by <em>copyright</em> and trademark law, as
            it cannot be used, except for the functions granted to the Influencer and
            the Brand according to the provisions of the GTCI and the GTCB.
          </p>
          <p>
            6.2 The right granted in the GTCI and the GTCB to the Influencer and the
            Brand to use the functionalities of the Social Collabz platform does not
            entitle any right to use them outside of these provisions.
          </p>
          <p>
            6.3 All rights, titles, and interests in all trademarks, logos, images,
            buttons, codes, <em>layouts</em>, texts, content, products, and services of
            Social Collabz on the Social Collabz platform (the "    <strong>Social Collabz platform Elements</strong>") are the exclusive
            property of Social Collabz and will remain the exclusive property of Social Collabz
            at all times.
          </p>
          <p>
            6.4 Social Collabz may grant a limited and non-exclusive license to use the
            Elements of the Social Collabz platform or some of them to the extent that
            it deems such concession functional to the use of the Social Collabz
            platform.
          </p>
          <p>
            6.5 In the event of a violation of individual prohibitions and/or
            non-compliance with the individual obligations referred to in the previous
            points, Social Collabz reserves the right to suspend and/or exclude the Brand
            from the Social Collabz platform.
          </p>
          <p>
            <strong>7. </strong>
            <strong>ADDITIONAL INFLUENCER AND BRAND</strong>
            STATEMENTS<strong></strong>
          </p>
          <p>
            7.1 The Influencer and the Brands operating on the Social Collabz platform
            guarantee, declare, and agree that:
          </p>
          <p>
            a. all information provided to Social Collabz when creating the Influencer
            Account and Brand Account is true, accurate, complete, and not misleading;
          </p>
          <p>
            b. any other account on social <em>media</em> and/or    <em>third party social networks</em> to which they are subscribed and/or
            connected is their own <em>account</em>;
          </p>
          <p>
            c. if the Account refers to a Brand, that account is owned by the
            authorized representative of the Brand with the right to access its social
            media accounts and represent its interests;
          </p>
          <p>
            d. if the <em>account</em> refers to another subject, specific
            authorization has been made, also concerning access to <em>accounts</em> on
            social <em>media</em> and/or <em>social networks</em> representing its
            interest;
          </p>
          <p>
            e. they have the right and/or authorization to create an Influencer Account
            or a Brand Account and accept the GTCI and the GTCB;
          </p>
          <p>
            f. they are authorized to send material to the Social Collabz platform in
            accordance with the GTCI and the GTCB;
          </p>
          <p>
            g. all material sent to the Social Collabz platform will not have content
            that:
          </p>
          <p>
            i.is illegal and/or violates applicable regulatory requirements of any
            degree or level;
          </p>
          <p>
            ii.is copied and/or adapted in whole and/or substantially from other
            material;
          </p>
          <p>
            iii.is indecent, obscene, threatening, discriminatory, harassing, violates
            security, in breach of any confidence, defamatory, offensive or
            objectionable or contains, depicts or alludes to or promotes aggressive,
            unruly, antisocial, lewd or illegal behavior or sexually suggestive
            imagery;
          </p>
          <p>
            iv.parodies disparages and/or makes fun of Social Collabz and/or its products
            and/or services in any other way;
          </p>
          <p>
            v.requests<em> password</em> and/or personal information for commercial
            and/or illicit purposes or provides any telephone number, postal address,
            surname, URL, or <em>email</em> address (unless the presence of the
            indicated elements, as regards the only lawful ones, is expressly requested
            in the Campaign);
          </p>
          <p>
            vi.promotes illegal and/or unauthorized of another person’s copyrighted
            work (such as but not limited to, providing pirated <em>computer</em>
            programs or <em>links</em> to them, providing information to circumvent
            protected devices, or providing pirated material);
          </p>
          <p>
            vii.includes the transmission of junk mail, chain letters, bulk sending of
            junk mail or <em>spam</em>;
          </p>
          <p>
            viii.violates the <em>privacy</em>, contractual rights, and/or other rights
            of any person (including Intellectual Property Rights).
          </p>
          <p>
            7.2 In the event of a violation of individual prohibitions and/or
            non-compliance with the individual obligations referred to in the previous
            points, Social Collabz reserves the right to suspend and/or exclude the Brand
            from the Social Collabz platform.
          </p>
          <p>
            <strong>8. </strong>
            <strong>
              PROVISIONS ON THE RELATIONSHIP BETWEEN INFLUENCERS AND BRANDS
            </strong>
            <strong></strong>
          </p>
          <p>
            8.1 the Influencer and the Brand acknowledge that:
          </p>
          <p>
            a. neither the Influencer nor Social Collabz is required to purchase any of the
            Brand’s products or services;
          </p>
          <p>
            b. Brand may, at its sole discretion, arrange to send an Influencer a
            sample product;
          </p>
          <p>
            c. the Influencer can ask the Brand to send a sample product, but the Brand
            is under no obligation to do so;
          </p>
          <p>
            d. Social Collabz will not be responsible in any way for delays in delivery
            and/or non-delivery of products sent by the Brand to the Influencer;
          </p>
          <p>
            e. the address provided by the Influencer via the Social Collabz platform
            is not verified by Social Collabz;
          </p>
          <p>
            f. if the Brand sends a sample product to the Influencer, there is no
            guarantee that the Influencer will make a post about that product or that
            any Post submitted will be positively evaluated by the Brand;
          </p>
          <p>
            g. if the Influencer chooses to purchase a product, there is no guarantee
            that the Influencer's post about the product will be approved by the Brand;
          </p>
          <p>
            h. the GTCB and the GTCI do not provide for any right in favor of the Brand
            on the Intellectual Property Rights on the Posts and/or on the Identity of
            the Influencer;
          </p>
          <p>
            i. the GTCB and the GTCI do not provide for any property rights in favor of
            the Influencer on the Brand's Intellectual Property Rights;
          </p>
          <p>
            j. the GTCB and the GTCI do not provide that the Brand must make use of the
            rights granted to the Brand by the Influencer;
          </p>
          <p>
            k. that the Brand and the Influencer agree to send drafts and/or samples,
            the related reports must be executed exclusively through the Social Collabz
            platform.
          </p>
          <p>
            8.2 In the event of a violation of individual prohibitions and/or
            non-compliance with the individual obligations referred to in the previous
            points, Social Collabz reserves the right to suspend and/or exclude the Brand
            from the Social Collabz platform.
          </p>
          <p>
            <strong>9. </strong>
            <strong>
              GUARANTEES AND INDEMNITIES OF INFLUENCERS AND BRANDS IN FAVOR OF
              SOCIAL COLLABZ
            </strong>
            <strong></strong>
          </p>
          <p>
            9.1 The Influencer and the Brand agree to indemnify Social Collabz and its
            member bodies, its staff, its officers, and its agents from and against any
            type of claim, liability, damage, loss, and expense arising out of or
            connected in any way to the following ( including as a result of your
            direct activities on the Social Collabz platform):
          </p>
          <p>
            a. the materials uploaded to the Social Collabz platform and/or access to
            it;
          </p>
          <p>
            b. the violation of the GTCI and the GTCB and/or any guarantee made and/or
            stipulated agreement;
          </p>
          <p>
            c. claims against a Brand for any reason;
          </p>
          <p>
            d. claims against an Influencer for any reason;
          </p>
          <p>
            e. any claim by third parties (including a different Brand and/or
            Influencer) arising directly and/or indirectly from the breach of any
            provision of the GTCI and/or the GTCB;
          </p>
          <p>
            f. any claim also concerning the fact that the materials uploaded to the
            Social Collabz platform violate the rights of third parties, including
            Intellectual Property Rights;
          </p>
          <p>
            g. the violation of any applicable legislation.
          </p>
          <p>
            9.2 In the event of a violation of individual prohibitions and/or
            non-compliance with the individual obligations referred to in the previous
            points, Social Collabz reserves the right to suspend and/or exclude the Brand
            from the Social Collabz platform.
          </p>
          <p>
            <strong>10. </strong>
            <strong>LIMITATION OF LIABILITY IN FAVOR OF SOCIAL COLLABZ</strong>
            <strong></strong>
          </p>
          <p>
            10.1 By using the Social Collabz platform, the Influencer and/or Brand may
            be exposed to content that presents characteristics of dangerousness,
            obscenity, deceptiveness, and/or neglect. Under no circumstances will
            Social Collabz be liable in any way for any Content, including but not limited
            to any errors or omissions in such Content or any loss or damage of any
            kind incurred as a result of any use of Content posted, transmitted, or
            otherwise made available via the Social Collabz platform.
          </p>
          <p>
            10.2 Social Collabz's liability to the Brand about the GTCI and the GTCB will be
            limited to the amount paid by the Brand to Social Collabz for services related
            to the most recent Advertising Campaign. Social Collabz shall not be liable to
            Influencers for damages of any kind arising from the use of the Social
            Collabz platform. In no event shall Social Collabz and/or any of its associated
            entities, successors in title, licensee, and/or licensor be liable for any
            direct, indirect, special, incidental, consequential, punitive, and/or
            exemplary damages arising out of and/or in connection with the Social
            Collabz platform, the GTCI and/or the GTCB and/ or any Posts, material
            uploaded into the Social Collabz platform and/or Campaign. The foregoing
            limitations apply whether the alleged liability is based on tort, contract,
            negligence, or any other basis, even if Social Collabz or any other party has
            been advised of the possibility of such damages.
          </p>
          <p>
            10.3 Social Collabz makes no warranties and assumes no commitment that the
            Social Collabz platform is always available and/or is always error-free,
            reliable and/or safe, and/or free of viruses and/or other dangerous
            components. The Influencer and the Brand use the Social Collabz platform
            assuming the inherent and consequent risks, accepting that Social Collabz
            disclaims all responsibility for damages and/or losses that may occur,
            regardless of whether they are direct or consequent. The Influencer and the
            Brand accept that Social Collabz disclaims any responsibility for losses
            resulting from any interference, malfunction, delay, failure, and/or damage
            that may occur to the device used and/or that derive from the access,
            installation, updating, and/or use of the Social Collabz platform. The
            Influencer and the Brand assume responsibility for the use of the Social
            Collabz platform, including compliance with all applicable laws and
            regulations.
          </p>
          <p>
            10.4 In the event of a violation of individual prohibitions and/or
            non-compliance with the individual obligations referred to in the previous
            points, Social Collabz reserves the right to suspend and/or exclude the Brand
            from the Social Collabz platform.
          </p>
          <p>
            <strong>11. </strong>
            <strong>MONITORING</strong>
            <strong></strong>
          </p>
          <p>
            11.1 Social Collabz reserves the right to monitor the Social Collabz platform
            and all activities of Influencer Accounts and Brand Accounts.
          </p>
          <p>
            11.2 If in the execution of this monitoring activity Social Collabz finds signs
            of any kind of fraud, abuse, or suspicious activity, Social Collabz may at its
            discretion delete or suspend the Influencer Account and/or the Brand
            Account that was the author or participated in violation.
          </p>
          <p>
            11.3 Social Collabz retains the right to remove material uploaded to the Social
            Collabz platform that it considers to be a violation of the GTCI and/or the
            GTCB.
          </p>
          <p>
            11.4 Social Collabz reserves the right, in all cases regulated by the previous
            point, to take action for damages against the owner of the Influencer
            Account and/or the Brand Account.
          </p>
          <p>
            <strong>12. </strong>
            <strong>REASONS OF FORCE MAJEURE</strong>
            <strong></strong>
          </p>
          <p>
            12.1 If the Social Collabz platform is not able to function for any reason
            or/or event beyond the possibilities of repair and/or maintenance by
            Social Collabz, Social Collabz reserves the right to provide, at its discretion, to
            cancel, terminate, modify or suspend access to it.
          </p>
          <p>
            12.2 Reasons for force majeure are acts of war, terrorism, states of
            emergency or disaster (including natural disasters), computer virus
            infections, <em>bugs</em>, tampering, unauthorized interventions, technical
            failures, and/or any event that may significantly affect the
            administration, security, integrity and/or proper functioning of the Social
            Collabz platform.
          </p>
          <p>
            <strong>13. </strong>
            <strong>EFFECTIVENESS OF </strong>
            GTCI<strong> AND GTCB</strong><strong></strong>
          </p>
          <p>
            13.1 The GTCI and the GTCB prevail over any other agreement entered into by
            Social Collabz, the Influencer, and/or the Brand.
          </p>
          <p>
            13.2 If one or more clauses of the GTCI and/or GTCB are considered null
            and/or voidable and/or invalid and/or e unenforceable and/or ineffective,
            this circumstance will not affect the validity of the other separate
            clauses, which must be considered fully in force and effect.
          </p>
          <p>
            <strong>14. APPLICABLE LAW AND EXCLUSIVE JURISDICTION</strong>
            <strong></strong>
          </p>
          <p>
            14.1 The GTCI and the GCCB are governed by Italian law and it is expressly
            agreed that the Court of Milan has exclusive jurisdiction for any dispute
            arising from them, also concerning their validity, interpretation, and
            execution.
          </p>
          <p>
            <strong>15. </strong>
            <strong>COMMUNICATIONS</strong>
            <strong></strong>
          </p>
          <p>
            15.1 For the execution of the GTCI and the GTCB, for any communication with
            the Influencer and the Brand, Social Collabz provides the following addresses:
            i) for communications by post:Nordstrasse 1, 6300 Zug, Switzlerand, CH499205145; ii)
            for communications by <em>email</em>:    <a href="mailto:info@socialcollabz.com">info@socialcollabz.com</a>.
          </p>
          <p>
            <strong>16. </strong>
            <strong>INTERPRETATION AND DEFINITIONS</strong>
          </p>
          <p>
            1 6.1 In the GTCI and the GTCB:
          </p>
          <p>
            a. words that indicate a gender also include all other genders;
          </p>
          <p>
            b. the singular includes the plural and vice versa;
          </p>
          <p>
            c. a term derived from another defined term has a corresponding meaning;
          </p>
          <p>
            d. any reference to a natural person includes his or her representatives,
            successors, and permitted assigns;
          </p>
          <p>
            e. Monetary amounts are expressed in Australian dollars or euros.
          </p>
          <p>
            <a name="_Hlk5212956"></a>
            <a name="_Hlk5111873">
              16.2 The following terms have the meanings in the GTCI and the GTCB
              respectively:
            </a>
            <a name="_Hlk5111895"></a>
          </p>
          <p>
            a. "Brand <strong>Account</strong>" means the registration <em>account</em>
            on the Social Collabz platform created and used by the Brand;
          </p>
          <p>
            b. "Influencer <strong>Account</strong>" means the registration    <em>account</em> on the Social Collabz platform created and used by the
            Influencer;
          </p>
          <p>
            <a name="_Hlk5220713">
              c. "<strong>Media Agency</strong>" means any person who carries out the
              activity of
            </a>
            social <em>networks</em> and/or <em>social media</em>;
          </p>
          <p>
            d. "<strong>Social Collabz App</strong>" means the application through which the
            Influencer accesses the use of the Social Collabz platform, available for    <em>download</em> on https://www.apple.com/itunes/ and Google Play;
          </p>
          <p>
            e. "<strong>Brand</strong>" means each entity that uses the Social Collabz
            platform to create an Advertising Campaign;
          </p>
          <p>
            f. "Campaign <strong>Budget</strong>" means the amount of money that the
            Brand dedicates to the realization of the Advertising Campaign;
          </p>
          <p>
            g. "Advertising <strong>Campaign</strong>" means the Advertising Campaign
            commissioned by the Brand to the Influencer via the Social Collabz
            platform;
          </p>
          <p>
            h. "Campaign <strong>Builder</strong>" means the <em>desktop</em> platform
            that the Brand uses to create an Advertising Campaign to be uploaded to the
            Social Collabz platform and is available for <em>download</em> on
            https://socialcollabz.com;
          </p>
          <p>
            i. "<strong>Social Influencer Channels</strong>" means the social    <em>media</em> and/or <em>social network</em> channels of an Influencer,
            such as Twitter, Facebook, or Instagram;
          </p>
          <p>
            j. "<strong>GTCB"</strong> means the General Terms and Conditions of Brand;
          </p>
          <p>
            k. "<strong>GTCI"</strong> means the General Terms and Conditions of
            Influencer Contract;
          </p>
          <p>
            l. "<strong>Post</strong> <strong>Fee</strong>" means the economic
            consideration recognized by the Brand to the Influencer following the
            approval of the Post and its publication;
          </p>
          <p>
            m. "<strong>Influencer</strong> <strong>Community</strong>" means an
            Influencer's followers on its Social Influencer Channels;
          </p>
          <p>
            n. "<strong>Content Right</strong>" means the procedure provided by the
            Social Collabz platform with which the Influencer sells to the Brand the
            rights of the Post subject to the approval procedure;
          </p>
          <p>
            o. "<strong>Intellectual Property Rights</strong>" means all current or
            future intellectual property rights of any nature exercisable anywhere in
            the world including, but not limited to, rights relating to or in
            connection with <em>copyrights</em>, inventions (including patents),
            trademarks, service marks, trade names, domain names, <em>designs</em>. ,
            any rights related to the topography of silicon chips, confidential
            information, <em>know-how</em> and similar, industrial and commercial
            intellectual property rights, whether or not registrable or registered,
            including the right to request the registration of such rights;
          </p>
          <p>
            p. "<strong>Elements of the Social Collabz platform</strong>" means all
            rights, titles, and interests in and to all trademarks, logos, images,
            buttons, codes, <em>layouts</em>, texts, content, products, and services
            owned exclusively by Social Collabz and present on the Social Collabz platform;
          </p>
          <p>
            q. "<strong>Social Collabz</strong>" means Social Collabz GmbH, tax code, and
            registration number in the register of companies of
            Nordstrasse 1, 6300 Zug, Switzlerand, CH499205145, with registered
            office in Nordstrasse 1, 6300 Zug, (Switzlerand);
          </p>
          <p>
            r. "<strong>Influencer Identity</strong>" means    <strong>the Influencer's</strong> name, image, a distinctive sign,
            character, and online character ;
          </p>
          <p>
            s. "<strong>Influencer</strong>" means a <em>social media influencer</em>
            who registers on the Social Collabz platform to create Posts and receive
            compensation from Brands through the same;
          </p>
          <p>
            t. "<strong>Social Collabz platform</strong>" means the Social Collabz App, the
            Campaign Builder, and any other functionality and/or <em>website</em> owned
            and/or associated with Social Collabz;
          </p>
          <p>
            u. "<strong>Post</strong>" means a <em>social media</em> and/or    <em>social network</em> <em>post</em> created by an Influencer and to be
            shared with the Influencer Community via the Social Influencer Channels;
          </p>
          <p>
            v. "<strong>Linked Sites</strong>" means the <em>websites</em> accessible
            via certain features of the Social Collabz platform;
          </p>
          <p>
            w. "<strong>Connected</strong> Socials" means <em>social media</em> and/or    <em>social networks</em> accessible via certain features of the Social
            Collabz platform;
          </p>
          <p>
            x. "<strong>Wallet</strong>" means the functionality of the Social Collabz
            platform through which the Brand allocates a certain <em>budget</em> for a
            given activity.
          </p>
        </div>
      </div>
    </div>
  </section>
</main>
