<svg class="modal-close-btn" (click)="ref.close()" width="14" height="14" viewBox="0 0 14 14" fill="none"
     xmlns="http://www.w3.org/2000/svg">
  <path
    d="M8.813 4.2L7 6.013L5.187 4.2L4.2 5.187L6.013 7L4.2 8.813L5.187 9.8L7 7.987L8.813 9.8L9.8 8.813L7.987 7L9.8 5.187L8.813 4.2ZM7 0C3.129 0 0 3.129 0 7C0 10.871 3.129 14 7 14C10.871 14 14 10.871 14 7C14 3.129 10.871 0 7 0ZM7 12.6C3.913 12.6 1.4 10.087 1.4 7C1.4 3.913 3.913 1.4 7 1.4C10.087 1.4 12.6 3.913 12.6 7C12.6 10.087 10.087 12.6 7 12.6Z"
    fill="#070707FF" />
</svg>

<mat-horizontal-stepper linear style="overflow: auto" #stepper>
  <mat-step>
    <app-create-brand [formGroup]="brandForm"></app-create-brand>
  </mat-step>

  <mat-step>
    <div class="modal modal-reg">
      <app-brand-information
        [error]="error"
        [formGroup]="brandUserForm">
      </app-brand-information>
      <div class="modal-reg__buttons">
        <button type="button" class="btn btn--bg btn--tertiary btn--modal"
                matStepperPrevious>{{ 'regDialog.back' | translate }}
        </button>

        <button type="button" class="btn btn--bg btn--primary btn--modal"
                [disabled]="brandUserForm.invalid" matStepperNext>{{ 'regDialog.next' | translate }}
        </button>
      </div>
    </div>
  </mat-step>

  <mat-step>
    <ng-container *ngIf="stepper.selectedIndex === 2">
      <app-choose-plan *ngIf="!loading" [currency]="brandUserForm.value.currency" [formGroup]="planForm"
                       (submitEmitter)="submit($event)"></app-choose-plan>
      <div *ngIf="loading" class="progress-spinner absolute">
        <mat-spinner></mat-spinner>
      </div>
    </ng-container>
  </mat-step>
</mat-horizontal-stepper>
